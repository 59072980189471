import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"; // Import the useParams hook
import axios from "axios";
import img from "../Assets/banner.png";
import img1 from "../Assets/profile.png";
import img2 from '../Assets/img3.png'
import collegefee from "../Assets/collegefee.png";
import hostelfee from "../Assets/hostelfee.png";
import messfee from '../Assets/messfee.png';
import total from '../Assets/total.png';
import overall from '../Assets/overall.png';
import classes from "./Billing.module.css";
import msalInstance from "../msalConfig";

function StudentProfile() {
    const { studentid } = useParams(); // Use useParams to get the route parameter
    const [studentData, setStudentData] = useState({});
    const [searchId, setSearchId] = useState('');
    const [hostelFee, setHostelFee] = useState(null);
    const [messFee, setMessFee] = useState(null);
    const[college, setCollegeFee] = useState(null);
    const [collectedFee, setCollectedFee] = useState(null);
    const [fee, setFee] = useState(null);
    const [pendingFeee, setPendingFeee] = useState(null);
    const [visaFee, setVisaFee] = useState(null);
    const [registrationFee, setRegistrationFee] = useState(null);
    const [yeartotal, setYeartotal] = useState(null);
  
   
    const [fees, setFees] = useState([
        {
            
            year: 0,
            fee_to_be_collected: 0,
            mess_fee: 0,
            visa_fee: 0,
            hostel_fee: 0,
            visa_extension_fee: 0,
            

        },
    ]);

    console.log("PendingFee",pendingFeee);

    const [originalFees, setOriginalFees] = useState([...fees]); // Save original fees    
    
    const handleSearchChange = (event) => {
        setSearchId(event.target.value); // Update search input
    };

   
        // Function to fetch data
        const fetchData = async () => {
          try {
            const accounts = msalInstance.getAllAccounts();
                if (accounts.length === 0) {
                    throw new Error("No accounts found");
                }
                msalInstance.setActiveAccount(accounts[0]);
            const token = await msalInstance.acquireTokenSilent({
              scopes: ["user.read"]
          });
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/collectedfee/calculatePendingFee?student_id=${studentid}`, {
              headers: {
                authorization: `Bearer ${token.idToken}`
              }
            });
            setPendingFeee(response.data.pending_fee);  
            console.log("PendingFee:", response.data.pending);
            setCollectedFee(response.data.total_collected); 
          } catch (error) {
            console.error("Error fetching student data:", error);
          }
        };
      
        useEffect(() => { 
        fetchData();
          
      }, [searchId]);
      
      useEffect(() => {
        if (pendingFeee !== null) {
          console.log("PendingFee:", pendingFeee);  
        }
      }, [pendingFeee]); 


      
        const fetchStudentData = async () => {
          try {
            // Fetch the token
            const token = await msalInstance.acquireTokenSilent({
              scopes: ["user.read"]
            });
      
            // Make the API request with the token
            const response = await axios.get(
              `${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/yearlyfee/${searchId}`,
              {
                headers: {
                  Authorization: `Bearer ${token.idToken}`,
                }
              }
            );
           // Update state with the fetched data
            setCollectedFee(response.data.fee_collected);
            setFee(response.data.fee_to_be_collected);
            setHostelFee(response.data.hostel_fee);
            setMessFee(response.data.mess_fee);
            setVisaFee(response.data.visa_fee);
            setRegistrationFee(response.data.visa_extension_fee);
            setCollegeFee(response.data.fee_to_be_collected);
            setYeartotal(response.data.total_fee);
            
    
            console.log("hostel",response.data.hostel_fee);
    
      
            console.log(response.data);
            console.log(response.data.fee_to_be_collected);
          } catch (error) {
            console.error("Error fetching student data:", error);
          }
        };
        useEffect(() => {
        fetchStudentData();
      }, [searchId]);  // Dependency array with searchid



    const handleSearchSubmit = async () => {
        // Check if the search ID is not empty
        if (searchId) {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                throw new Error("No accounts found");
            }
            msalInstance.setActiveAccount(accounts[0]);
            const token = await msalInstance.acquireTokenSilent({
                scopes: ["user.read"]
            });
            axios
                .get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent/${searchId}`,
                    {
                    headers: {
                        Authorization: `Bearer ${token.idToken}`
                    }
                    }
                )
                .then((response) => {
                    setStudentData(response.data); // Set the fetched data to state
                })
                .catch((error) => {
                    console.error("Error fetching student data:", error);
                });
        }
    };


    const handleFeesChange = (index, field, value) => {
      const updatedFees = fees.map((fee, i) => 
        i === index ? { ...fee, [field]: parseInt(value) } : fee
      );
    
      setFees(updatedFees);
    };
    
    const handleUpdateeer = async () => {
      try {
        // Update student details
        const studentResponse = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent/${searchId}`,
          studentData
        );
        console.log("Student details updated:", studentResponse);
    
        // Update fee details
        const feeToUpdate = fees[0];
        const payload = {};
        const originalFee = {
          fee_to_be_collected: 0,
          mess_fee: 0,
          visa_fee: 0,
          hostel_fee: 0,
          visa_extension_fee: 0,
        };
    
        // Check if any field is updated and dynamically build the payload
        let isAnyFieldUpdated = false;
    
        for (const key in feeToUpdate) {
          if (feeToUpdate[key] !== originalFee[key]) {
            payload[key] = feeToUpdate[key];
            isAnyFieldUpdated = true;
          }
        }
    
        // Proceed with the update only if any field is updated
        if (!isAnyFieldUpdated) {
          alert("No changes detected to update fees.");
          return;
        }
    
        const feeResponse = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/update/${searchId}/${feeToUpdate.year}`,
          payload
        );
        console.log("Fee updated successfully:", feeResponse);
    
        alert("Student and Fee details updated successfully!");
      } catch (error) {
        console.error("Error updating details:", error);
        alert("Failed to update student and/or fee details.");
      }
    };
    
    
    
    useEffect(() => {
        // If there's a studentid in the URL, fetch student data automatically
        const fetchStudentData = async () => {
            if (studentid) {
                const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                throw new Error("No accounts found");
            }
            msalInstance.setActiveAccount(accounts[0]);
                const token = await msalInstance.acquireTokenSilent({
                    scopes: ["user.read"]
                });
                axios
                    .get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent/${studentid}`, {
                        headers: {
                            Authorization: `Bearer ${token.idToken}`
                        }
                    })
                    .then((response) => {
                        setStudentData(response.data); // Set student data
                    })
                    .catch((error) => {
                        console.error("Error fetching student data:", error);
                    });
            }
        };
        fetchStudentData();
    }, [studentid]);


    return (
      <div
        className="container-fluid min-vh-100 d-flex justify-content-center"
        style={{ backgroundColor: "#1E1E2E" }}
      >
        <div className="row col-12">
          <nav
            style={{
              "--bs-breadcrumb-divider": `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E")`,
              fontSize: "1.5rem",
              fontWeight: 500,
              backgroundColor: "#1E1E2E",
            }}
            aria-label="breadcrumb"
            className="p-3 text-white d-flex justify-content-between align-items-center"
          >
            <ol className="breadcrumb m-2">
              <li className="breadcrumb-item text-white">Student Dashboard</li>
              <li className="breadcrumb-item active" aria-current="page"></li>
            </ol>
            <div className="d-flex align-items-center gap-3">
              <div className="col text-end">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control custom-placeholder"
                    placeholder="Enter Student ID"
                    aria-label="Search"
                    value={searchId}
                    style={{
                      width: "200px",
                      backgroundColor: "#2C2C3C",
                      border: "1px solid #444",
                      color: "#FFFFFF",
                    }}
                    onChange={handleSearchChange} // Handle input changes
                  />
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={handleSearchSubmit}
                  >
                    <i className="bi bi-search"></i> Search
                  </button>
                  <style>
                    {`
                                                .custom-placeholder::placeholder {
                                                color: rgba(255, 255, 255, 0.7); /* Light gray placeholder */
                                                }
                                                `}
                  </style>
                </div>
              </div>
            </div>
          </nav>
          <div className="container row mx-auto mb-5 justify-content-center">
            <div className="col-lg-9">
              <div
                className="container rounded"
                style={{ backgroundColor: "#2D2F3A" }}
              >
                <div className="col">
                  <img src={img} style={{ width: "100%" }} />
                  <img
                    src={img1}
                    className="d-flex justify-content-start"
                    style={{
                      marginTop: "-60px",
                      marginLeft: "40px",
                    }}
                  />
                  <div className="row m-4" style={{ color: "#ffffff" }}>
                    <div className="col text-start">
                      <p>
                        {studentData.firstname} {studentData.lastname}
                      </p>
                      <p className="text-start" style={{ color: "#EEEEEE" }}>
                        {studentData.email}
                      </p>
                    </div>
                  </div>
                  <div className="row m-4">
                    <div className="col">
                      <label
                        htmlFor="floatingFirstName"
                        className="form-label"
                        style={{ color: "#EEEEEE" }}
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        id="floatingFirstName"
                        value={studentData.firstname}
                        onChange={(e) =>
                          setStudentData({
                            ...studentData,
                            firstname   : e.target.value
                          })
                        }
                        disabled={false}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#EEEEEE",
                        }}
                      />
                    </div>
                    <div className="col">
                      <label
                        htmlFor="floatingLastName"
                        className="form-label"
                        style={{ color: "#EEEEEE" }}
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        id="floatingLastName"
                        value={studentData.lastname}
                        onChange={(e) =>
                          setStudentData({
                            ...studentData,
                            lastname: e.target.value,
                          })
                        }
                        disabled={false}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#EEEEEE",
                        }}
                      />
                    </div>
                  </div>

                  <div className="row m-4">
                    <div className="col">
                      <label
                        htmlFor="floatingPassport"
                        className="form-label"
                        style={{ color: "#EEEEEE" }}
                      >
                        Passport Number
                      </label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        id="floatingPassport"
                        value={studentData.passportnumber}
                        onChange={(e) =>
                          setStudentData({
                            ...studentData,
                            passportnumber: e.target.value,
                          })
                        }
                        disabled={false}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#EEEEEE",
                        }}
                      />
                    </div>
                  </div>

                  <div className="row m-4">
                    <div className="col">
                      <label
                        htmlFor="floatingNationality"
                        className="form-label"
                        style={{ color: "#EEEEEE" }}
                      >
                        Nationality
                      </label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        value={studentData.nationality}
                        onChange={(e) =>
                          setStudentData({
                            ...studentData,
                            nationality: e.target.value,
                          })
                        }
                        disabled={false}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#EEEEEE",
                        }}
                      />
                    </div>
                    <div className="col">
                      <label
                        htmlFor="floatingNationality"
                        className="form-label"
                        style={{ color: "#EEEEEE" }}
                      >
                        country
                      </label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        id="floatingNationality"
                        value={studentData.country}
                        onChange={(e) =>
                          setStudentData({
                            ...studentData,
                            country: e.target.value,
                          })
                        }
                        disabled={false}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#EEEEEE",
                        }}
                      />
                    </div>
                  </div>
                  <div className="row m-4">
                    <div className="col-md-6">
                      <label
                        htmlFor="floatingAddress"
                        className="form-label"
                        style={{ color: "#EEEEEE" }}
                      >
                        Address
                      </label>
                      <textarea
                        type="text"
                        className="form-control mb-3"
                        id="floatingAddress"
                        value={studentData.address}
                        onChange={(e) =>
                          setStudentData({
                            ...studentData,
                            address: e.target.value,
                          })
                        }
                        disabled={false}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#EEEEEE",
                          width: "205%",
                        }}
                      />
                    </div>
                  </div>
                  <div className="row m-4">
                    <div className="col">
                      <label
                        htmlFor="floatingYear"
                        className="form-label"
                        style={{ color: "#EEEEEE" }}
                      >
                        Year
                      </label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        id="floatingYear"
                        value={studentData.year}
                        onChange={(e) =>
                          setStudentData({
                            ...studentData,
                            year: e.target.value,
                          })
                        }
                        disabled={false}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#EEEEEE",
                        }}
                      />
                    </div>
                    <div className="col">
                      <label
                        htmlFor="floatingAddress"
                        className="form-label"
                        style={{ color: "#EEEEEE" }}
                      >
                        Date of birth
                      </label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        id="floatingAddress"
                        value={studentData.dateofbirth}
                        onChange={(e) =>
                          setStudentData({
                            ...studentData,
                            dateofbirth: e.target.value,
                          })
                        }
                        disabled={false}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#EEEEEE",
                        }}
                      />
                    </div>
                  </div>
                  <div className="row m-4">
                    <div className="col">
                      <label
                        htmlFor="floatingPhoneLocal"
                        className="form-label"
                        style={{ color: "#EEEEEE" }}
                      >
                        Phone (Local)
                      </label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        id="floatingPhoneLocal"
                        value={studentData.localphone}
                        onChange={(e) =>
                          setStudentData({
                            ...studentData,
                            localphone: e.target.value,
                          })
                        }
                        disabled={false}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#EEEEEE",
                        }}
                      />
                    </div>
                    <div className="col">
                      <label
                        htmlFor="floatingPhoneParent"
                        className="form-label"
                        style={{ color: "#EEEEEE" }}
                      >
                        Phone (Parent)
                      </label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        id="floatingPhoneParent"
                        value={studentData.phone}
                        onChange={(e) =>
                          setStudentData({
                            ...studentData,
                            phone: e.target.value,
                          })
                        }
                        disabled={false}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#EEEEEE",
                        }}
                      />
                    </div>
                  </div>
                  <div className="row m-4">
                    <div className="col">
                      <label
                        htmlFor="floatingFatherName"
                        className="form-label"
                        style={{ color: "#EEEEEE" }}
                      >
                        Father Name
                      </label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        id="floatingFatherName"
                        value={studentData.fathername}
                        onChange={(e) =>
                          setStudentData({
                            ...studentData,
                            fathername: e.target.value,
                          })
                        }
                        disabled={false}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#EEEEEE",
                        }}
                      />
                    </div>
                    <div className="col">
                      <label
                        htmlFor="floatingOccupation"
                        className="form-label"
                        style={{ color: "#EEEEEE" }}
                      >
                        Father Occupation
                      </label>
                      <input
                        type="text"
                        className="form-control mb-3"
                        id="floatingOccupation"
                        value={studentData.fatheroccupation}
                        onChange={(e) =>
                          setStudentData({
                            ...studentData,
                            fatheroccupation: e.target.value,
                          })
                        }
                        disabled={false}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#EEEEEE",
                        }}
                      />
                    </div>
                    
                  </div>

                  <div>
                    <div style={{ padding: "20px", margin: "auto" }}>
                      <h6 style={{ color: "#EEEEEE" }}>
                        FeeUpdation Management
                      </h6>
                      <table
                        style={{
                          width: "100%",
                          borderCollapse: "collapse",
                          border: "none",
                          width: "100%",
                        }}
                      >
                        <thead style={{ backgroundColor: "#3A3F51" }}>
                          <tr>
                            <th
                              style={{
                                padding: "8px",
                                color: "#A0A0A0",
                                fontWeight: 500,
                              }}
                            >
                              Year
                            </th>
                            <th
                              style={{
                                padding: "8px",
                                color: "#A0A0A0",
                                fontWeight: 500,
                              }}
                            >
                              Tuition Fee
                            </th>
                            <th
                              style={{
                                padding: "8px",
                                color: "#A0A0A0",
                                fontWeight: 500,
                              }}
                            >
                              Mess fee
                            </th>
                            <th
                              style={{
                                padding: "8px",
                                color: "#A0A0A0",
                                fontWeight: 500,
                              }}
                            >
                              Visa fee
                            </th>
                            <th
                              style={{
                                padding: "8px",
                                color: "#A0A0A0",
                                fontWeight: 500,
                              }}
                            >
                              Hostel fee
                            </th>
                            <th
                              style={{
                                padding: "8px",
                                color: "#A0A0A0",
                                fontWeight: 500,
                              }}
                            >
                              Visa Extension Fee
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {fees.map((fee, index) => (
                            <tr
                              key={index}
                              style={{ border: "1px solid #3E3E3E" }}
                            >
                              <td style={{ color: "#EEEEEE", padding: "8px" }}>
                                <select value={fee.year}
                                onChange={(e) => handleFeesChange(index,"year",e.target.value)}
                                style={{
                                    width:"100%",
                                    padding:'5px',
                                    backgroundColor:'rgba(58, 63,81,1)',
                                    color:'#ffffff',
                                    border:'1px soild rgba(255,255,255,0.5)',
                                    borderRadius:'4px'

                                }}>
                                    <option value={0}>Select Year</option>
                                    <option value={1}>I</option>
                                    <option value={2}>II</option>
                                    <option value={3}>III</option>
                                    <option value={4}>IV</option>
                                    <option value={5}>V</option>

                                </select>
                              </td>
                              <td style={{ color: "#EEEEEE", padding: "8px" }}>
                                <input
                                  type="text"
                                  inputMode="numeric"
                                  value={fee.fee_to_be_collected || ""}
                                  onChange={(e) =>{
                                    const value = e.target.value;
                                    handleFeesChange(
                                      index,
                                      "fee_to_be_collected",
                                      value === "" ? "": parseFloat(value) || 0
                                    )
                                  }}
                                  style={{ width: "100%", padding: "5px",backgroundColor:'rgba(58, 63,81,1)',color:'#ffffff', border:'1px solid rgba(255,255,255,0.5)',borderRadius:'4px' }}
                                />
                              </td>
                              <td style={{ color: "#EEEEEE", padding: "8px"  }}>
                                <input
                                  type="text"
                                  inputMode="numeric"
                                  value={fee.mess_fee || ""}
                                  onChange={(e) =>{
                                    const value = e.target.value;
                                    handleFeesChange(
                                      index,
                                      "mess_fee",
                                      value === "" ? "" : parseFloat(value) || 0
                                    )
                                  }}
                                  style={{ width: "100%", padding: "5px",backgroundColor:'rgba(58, 63,81,1)',color:'#ffffff', border:'1px solid rgba(255,255,255,0.5)',borderRadius:'4px' }}
                                />
                              </td>
                              <td style={{ color: "#EEEEEE", padding: "8px" }}>
                                <input
                                  type="text"
                                  inputMode="numeric"
                                  value={fee.visa_fee || ""}
                                onChange={(e) =>{
                                  const value = e.target.value;
                                    handleFeesChange(
                                      index,
                                      "visa_fee",
                                      value === "" ? "": parseFloat(value) || 0
                                    )
                                  }}
                                  style={{ width: "100%", padding: "5px",backgroundColor:'rgba(58, 63,81,1)',color:'#ffffff', border:'1px solid rgba(255,255,255,0.5)',borderRadius:'4px' }}
                                />
                              </td>
                              <td style={{ color: "#EEEEEE", padding: "8px" }}>
                                <input
                                  type="text"
                                  inputMode="numeric"
                                  value={fee.hostel_fee || ""}
                                  onChange={(e) =>{
                                    const value = e.target.value;
                                    handleFeesChange(
                                      index,
                                      "hostel_fee",
                                      value === "" ? "": parseFloat(value) || 0
                                    )
                                  }}
                                  style={{ width: "100%", padding: "5px",backgroundColor:'rgba(58, 63,81,1)',color:'#ffffff', border:'1px solid rgba(255,255,255,0.5)',borderRadius:'4px' }}
                                />
                              </td>
                              <td style={{ color: "#EEEEEE", padding: "8px" }}>
                                <input
                                  type="text"
                                  inputMode="numeric"
                                  value={fee.visa_extension_fee || ""}
                                  onChange={(e) =>{
                                    const value = e.target.value;
                                    handleFeesChange(
                                      index,
                                      "visa_extension_fee",
                                      value === "" ? "": parseFloat(value) || 0
                                    )
                                  }}
                                  style={{ width: "100%", padding: "5px",backgroundColor:'rgba(58, 63,81,1)',color:'#ffffff', border:'1px solid rgba(255,255,255,0.5)',borderRadius:'4px' }}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/* Other fields here... */}
                  <div
                    className="d-flex justify-content-end gap-3"
                    style={{ padding: "20px 40px" }}
                  >
                    <button
                      type="button"
                      className="btn"
                      style={{
                        backgroundColor: "#4182F9",
                        color: "#FFFFFF",
                        width: "100px",
                      }}
                      onClick={async () => {
                        await handleUpdateeer();
                        await fetchStudentData();
                      }}
                    >
                      UpdateFee
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* {isModalvisible && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                backdropFilter: "blur(8px)", // Apply blur effect
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  backgroundColor: "#1E1E2E",
                  padding: "20px 30px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                  textAlign: "center",
                  width: "90%",
                  maxWidth: "400px",
                  zIndex: 1001,
                }}
              >
                <p
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#EEE",
                  }}
                >
                  {modalmessage}
                </p>
                <button
                  onClick={closemodal}
                  style={{
                    backgroundColor: "#007bff",
                    color: "white",
                    border: "none",
                    padding: "8px 16px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          )} */}

            <div className="col-lg-3">
              <div className="row gap-3">
                <div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{
                    width: "360px",
                    height: "100px",
                    backgroundColor: "rgba(45, 47, 58, 1)",
                  }}
                >
                  <div>
                    <span className={classes.cardstitles}>
                      Tuition Fee
                    </span>
                    <br />
                    <span className={classes.cardsnumber}>{college || originalFees[0]?.fee_to_be_collected || 0}</span>
                  </div>
                  <img
                    src={collegefee}
                    width={40}
                    height={40}
                    alt="College Fee"
                  />
                </div>
                <div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{
                    width: "360px",
                    height: "100px",
                    backgroundColor: "rgba(45, 47, 58, 1)",
                  }}
                >
                  <div>
                    <span className={classes.cardstitles}>
                      Hostel Fee 
                    </span>
                    <br />
                    <span className={classes.cardsnumber}>{hostelFee || originalFees[0]?.hostel_fee || 0}</span>
                  </div>
                  <img
                    src={hostelfee}
                    width={40}
                    height={40}
                    alt="Hostel Fee"
                  />
                </div>
                <div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{
                    width: "360px",
                    height: "100px",
                    backgroundColor: "rgba(45, 47, 58, 1)",
                  }}
                >
                  <div>
                    <span className={classes.cardstitles}>
                    Mess Fee
                    </span>
                    <br />
                    <span className={classes.cardsnumber}>{messFee || originalFees[0]?.mess_fee || 0}</span> 
                  </div>
                  <img
                    src={messfee}
                    width={40}
                    height={40}
                    alt="Total Tickets Sold"
                  />
                </div>
                <div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{
                    width: "360px",
                    height: "100px",
                    backgroundColor: "rgba(45, 47, 58, 1)",
                  }}
                >
                  <div>
                    <span className={classes.cardstitles}>Visa Registration</span>
                    <br />
                    <span className={classes.cardsnumber}>{visaFee || originalFees[0]?.visa_fee || 0}</span>
                  </div>
                  <img
                    src={messfee}
                    width={40}
                    height={40}
                    alt="Total Tickets Sold"
                  />
                </div>
                <div
                  className="col-lg-3 col-md-6 col-sm-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{
                    width: "360px",
                    height: "100px",
                    backgroundColor: "rgba(45, 47, 58, 1)",
                  }}
                >
                  <div>
                    <span className={classes.cardstitles}>Visa Extension Per Year</span>
                    <br />
                    <span className={classes.cardsnumber}>
                      { registrationFee || originalFees[0]?. visa_extension_fee || 0}
                    </span>
                  </div>
                  <img
                    src={collegefee}
                    width={40}
                    height={40}
                    alt="Total Tickets Sold"
                  />
                </div>

                <div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{
                    width: "360px",
                    height: "100px",
                    backgroundColor: "rgba(45, 47, 58, 1)",
                  }}
                >
                  <div>
                    <span className={classes.cardstitles}>Total Fee</span>
                    <br />
                    <span className={classes.cardsnumber}>{yeartotal || originalFees[0]?.yeartotal || 0}</span>
                  </div>
                  <img
                    src={img2}
                    width={40}
                    height={40}
                    alt="Total Tickets Sold"
                  />
                </div>

                <div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{
                    width: "360px",
                    height: "100px",
                    backgroundColor: "rgba(45, 47, 58, 1)",
                  }}
                >
                  <div>
                    <span className={classes.cardstitles}>Total Paid Fee</span>
                    <br />
                    <span className={classes.cardsnumber}>{collectedFee}</span>
                  </div>
                  <img src={total} width={40} height={40} alt="Total Revenue" />
                </div>
                <div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{
                    width: "360px",
                    height: "100px",
                    backgroundColor: "rgba(45, 47, 58, 1)",
                  }}
                >
                  <div>
                    <span className={classes.cardstitles}>Pending Fee</span>
                    <br />
                    <span className={classes.cardsnumber}>{pendingFeee}</span>
                  </div>
                  <img
                    src={overall}
                    width={40}
                    height={40}
                    alt="Total Revenue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default StudentProfile;
