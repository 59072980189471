import React, { useState, useEffect } from "react";
import classes from "./Dashboard.module.css";
import dash from "../Assets/img1.png";
import dash1 from "../Assets/img2.png";
import dash2 from "../Assets/img3.png";
import Chart from "react-apexcharts";
import axios from "axios";
import msalInstance from "../msalConfig";


function Dashboard() {
  const [data, setData] = useState([]);
  const [totalStudents, setTotalStudents] = useState(0);
  const [totalCollectedFees, setTotalCollectedFees] = useState(0);
  const [lineChartData, setLineChartData] = useState([
    {
      name: "Total Students",
      data: [],
    },
  ]);
  const [categories, setCategories] = useState(["Initial Point"]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
          throw new Error("No accounts found");
        }
        msalInstance.setActiveAccount(accounts[0]);
        var token = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"],
        });

        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent`, {
          headers: {
            Authorization: `Bearer ${token.idToken}`,
          },
        });

        const students = response.data;
        setData(students);
        setTotalStudents(students.length);
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Fetch data from the API
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/totalcollected`)
      .then(response => {
        // Assuming the backend sends 'total_collected_fees' as the data field
        setTotalCollectedFees(response.data.total_collected_fees);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    if (totalStudents > 0) {
      setLineChartData((prevData) => {
        const newData = [...prevData[0].data, totalStudents];
        return [
          {
            name: "Total Students",
            data: newData,
          },
        ];
      });
      setCategories((prev) => [`Total Students`, ...prev]);
    }
  }, [totalStudents]);

  const lineChartOptions = {
    chart: {
      type: "area",
      height: 350,
      zoom: { enabled: false },
      background: "#2D2F3A",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight", // This ensures the graph has a smooth curve instead of sharp angles
      colors: ["#00E396"],
    },
    grid: {
      borderColor: "#444",
    },
    title: {
      text: "Total Students Over Time",
      align: "left",
      style: {
        fontSize: "18px",
        color: "#FFFFFF",
      },
    },
    xaxis: {
      type: "category",
      categories: categories,
      labels: {
        style: {
          colors: "#FFFFFF",
          fontSize: "12px",
        },
      },
      axisBorder: {
        color: "#FFFFFF",
      },
      axisTicks: {
        color: "#FFFFFF",
      },
      min: 11, // Ensure it doesn't start from 0, starts from the first data point
    },
    yaxis: {
      title: {
        text: "Number of Students",
        style: {
          color: "#FFFFFF",
        },
      },
      labels: {
        style: {
          colors: "#FFFFFF",
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      theme: "dark",
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      labels: {
        colors: "#FFFFFF",
      },
    },
    fill: {
      colors: ["#00E396"],
      opacity: 0.2,
    },
  };




  const [students, setStudents] = useState([]);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
          throw new Error("No accounts found");
        }
        msalInstance.setActiveAccount(accounts[0]);
        var token = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"],
        });

        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent`, {
          headers: {
            Authorization: `Bearer ${token.idToken}`,
          },
        });

        setStudents(response.data);
        prepareChartData(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const prepareChartData = (data) => {
    // Filter male and female students
    const maleStudents = data.filter(student => student.gender === 'Male');
    const femaleStudents = data.filter(student => student.gender === 'Female');

    // Count male and female students
    const maleCount = maleStudents.length;
    const femaleCount = femaleStudents.length;

    // Prepare chart data for male and female students
    setChartData([
      { name: 'Male', y: maleCount },
      { name: 'Female', y: femaleCount }

    ]);
  };


  const options = {
    chart: {
      type: 'pie'

    },

    labels: chartData.map(item => item.name),
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              fontSize: '16px',
              color: '#ffffff',
              offsetY: -10
            },
            value: {
              fontSize: '16px',
              color: '#ffffff',
              offsetY: 10
            }
          }
        }
      }
    }
  };

  const series = chartData.map(item => item.y);

  const [liChartData, setLiChartData] = useState([
    {
      name: "Total Fees Collected",
      data: [],
    },
  ]);

  const [categorie, setCategorie] = useState([]);

  useEffect(() => {
    // Fetch data from API
    const fetchFeesData = async () => {
      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
          throw new Error("No accounts found");
        }
        msalInstance.setActiveAccount(accounts[0]);
        var token = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"],
        });

        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/feeGroupedbydate`, {
          headers: {
            Authorization: `Bearer ${token.idToken}`
          }
        }); // Replace with your API URL
        const apiData = response.data.data;

        // Extract dates and fees
        const dates = apiData.map((item) => item._id); // Grouped dates
        const fees = apiData.map((item) => item.total_fee_collected); // Total fees

        // Update state for chart
        setLiChartData([
          {
            name: "Total Fees Collected",
            data: fees,
          },
        ]);
        setCategorie(dates);
      } catch (error) {
        console.error("Error fetching fees data:", error);
      }
    };

    fetchFeesData();
  }, []); // Only run once on mount

  const liChartOptions = {
    chart: {
      type: "area",
      height: 350,
      zoom: { enabled: false },
      background: "#2D2F3A",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight", // This ensures the graph has a smooth curve instead of sharp angles
      colors: ["#00E396"],
    },
    grid: {
      borderColor: "#444",
    },
    title: {
      text: "Total Fees Collected Over Time",
      align: "left",
      style: {
        fontSize: "18px",
        color: "#FFFFFF",
      },
    },
    xaxis: {
      type: "category",
      categories: categorie,
      labels: {
        style: {
          colors: "#FFFFFF",
          fontSize: "12px",
        },
      },
      axisBorder: {
        color: "#FFFFFF",
      },
      axisTicks: {
        color: "#FFFFFF",
      },
    },
    yaxis: {
      title: {
        text: "Fees Collected (in ₹)",
        style: {
          color: "#FFFFFF",
        },
      },
      labels: {
        style: {
          colors: "#FFFFFF",
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      theme: "dark",
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      labels: {
        colors: "#FFFFFF",
      },
    },
    fill: {
      colors: ["#00E396"],
      opacity: 0.2,
    },
  };

  return (
    <div className="container-fluid p-4 pt-5" style={{ backgroundColor: "#1E1E2E" }}>
      <p style={{ color: "#A0A0A0", fontSize: "18px", fontWeight: 400 }}>
        <b style={{ color: "#E0E0E0", fontWeight: 700 }}>Hey Rajasekar - </b>
        here’s what’s happening with your college today
      </p>

      <div className="container pt-4">
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3">
          <div className={classes.content}>
            <div className="col d-flex justify-content-center">
              <div
                className="p-3 d-flex justify-content-between align-items-center rounded-4"
                style={{
                  width: "100%",
                  maxWidth: "360px",
                  height: "100px",
                  backgroundColor: "#2D2F3A",
                }}
              >
                <div>
                  <span className={classes.cardstitle}>NO OF STUDENTS</span>
                  <br />
                  <span className={classes.cardsnumber}>{totalStudents}</span>
                </div>
                <img src={dash} width={40} height={40} alt="Total Students" />
              </div>
            </div>
          </div>

          <div className={classes.content}>
            <div className="col d-flex justify-content-center">
              <div
                className="p-3 d-flex justify-content-between align-items-center rounded-4"
                style={{
                  width: "100%",
                  maxWidth: "360px",
                  height: "100px",
                  backgroundColor: "#2D2F3A",
                }}
              >
                <div>
                  <span className={classes.cardstitle}>NEW STUDENTS</span>
                  <br />
                  <span className={classes.cardsnumber}>{totalStudents}</span> {/* Update this line */}
                </div>
                <img src={dash1} width={40} height={40} alt="New Students" />
              </div>
            </div>
          </div>
          <div className={classes.content}>
            <div className="col d-flex justify-content-center">
              <div
                className="p-3 d-flex justify-content-between align-items-center rounded-4"
                style={{
                  width: "100%",
                  maxWidth: "360px",
                  height: "100px",
                  backgroundColor: "#2D2F3A",
                }}
              >
                <div>
                  <span className={classes.cardstitle}>TOTAL COLLECTED FEES</span>
                  <br />
                  <span className={classes.cardsnumber}>{totalCollectedFees}</span> {/* Update this line */}
                </div>
              </div>
            </div>
          </div>

          {/* <div className={classes.content}>
      <div className="col d-flex justify-content-center">
        <div
          className="p-3 d-flex justify-content-between align-items-center rounded-4"
          style={{
            width: "100%",
            maxWidth: "360px",
            height: "100px",
            backgroundColor: "#2D2F3A",
          }}
        >
          <div>
            <span className={classes.cardstitle}>Total Earning</span>
            <br />
            <span className={classes.cardsnumber}>1,12,543</span>
          </div>
          <img src={dash2} width={40} height={40} alt="Total Revenue" />
        </div>
      </div>
    </div> */}
        </div>
      </div>

      <div className="row g-4 mt-1">
        <div className="col-lg-8">
          <div className="p-4 rounded-3" style={{ backgroundColor: "#2D2F3A" }}>
            <Chart options={lineChartOptions} series={lineChartData} type="area" height={350} />
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="p-4 rounded"
            style={{
              backgroundColor: "#2D2F3A",
              color: "#ffffff",
              height: "410px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",  // Vertically center the content
              alignItems: "center",      // Horizontally center the content
            }}
          >
            <h5 className="mb-5 text-light">Gender Distribution: Males vs. Females</h5>
            <div style={{ color: "#ffffff", width: "100%", display: "flex", justifyContent: "center" }}>
              <Chart options={options} series={series} type="pie" height="350" />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 mt-5">
        <div className="p-4 rounded-3" style={{ backgroundColor: "#2D2F3A" }}>
          <Chart
            options={liChartOptions}
            series={liChartData}
            type="area"
            height={350}
          />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
