import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Profile from "../Assets/profile.png";
import Classes from "./Receipt.module.css";
import download from '../Assets/download.png';
import resize from '../Assets/resize.png';
import print from '../Assets/print.png';
import view from '../Assets/_Button base.png'
import * as XLSX from "xlsx"; // Import xlsx library
import BillingRecipt from "./BillingRecipt";
import Delete from '../Assets/delete.png'
import msalInstance from "../msalConfig";

const StudentData = () => {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(""); // Search query state


  useEffect(() => {
    const fetchData = async () => {
      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
          throw new Error("No accounts found");
        }
        msalInstance.setActiveAccount(accounts[0]);
        const token = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"]
        });
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/populateStudent`, {
          headers: {
            Authorization: `Bearer ${token.idToken}`
          }
        });
        if (Array.isArray(response.data)) {
          setData(response.data);
          console.log(response.data);
        } else {
          console.error("Unexpected API response format:", response.data);
          setData([]);
        }
      } catch (err) {
        console.error("Error fetching student data:", err);
      }
    };

    fetchData();
  }, []);


  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleImageClick = (studentid, receiptid) => {
    navigate(`/app/billing-receipt/${studentid}/${receiptid}`);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  // Filters data based on the search query
  const filteredData = data.filter((item) => {
    const transactionId = item.transaction_id ? item.transaction_id.toString().toLowerCase() : '';
    const transactionType = item.transaction_type ? item.transaction_type.toString().toLowerCase() : '';
    const firstName = item.student_details?.firstname ? item.student_details.firstname.toLowerCase() : '';

    return (
      transactionId.includes(searchQuery.toLowerCase()) ||
      transactionType.includes(searchQuery.toLowerCase()) ||
      firstName.includes(searchQuery.toLowerCase())
    );
  });

  // Calculate paginated data
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Pagination handlers
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };


  // Handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Handle search query change and reset pagination
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page whenever the search query changes
  };

  const printRef = useRef();

  const handlePrint = () => {
    const printContent = printRef.current;
    const originalContent = document.body.innerHTML;

    // Replace the document body with the content of printRef
    document.body.innerHTML = printContent.innerHTML;
    window.print();

    // Restore the original document body
    document.body.innerHTML = originalContent;
    window.location.reload(); // Reload to restore event listeners
  };

  const handleToggleMenu = (id) => {
    setSelectedRow(selectedRow === id ? null : id); // Toggle menu visibility
  };

  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredData); // Convert data to worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Receipt Data");
    XLSX.writeFile(workbook, "ReceiptData.xlsx"); // Save file as StudentData.xlsx
  };

  const handleDeleteRow = async (studentId, receiptId) => {
    if (window.confirm("Are you sure you want to delete this student?")) {
      try {
        // Get all accounts
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
          throw new Error("No accounts found");
        }

        // Set active account
        msalInstance.setActiveAccount(accounts[0]);

        // Acquire token silently
        const tokenResponse = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"],
        });

        const token = tokenResponse.idToken;

        // Delete collected fee data via API
        await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/api/v1/collectedfee/collectedfee/${studentId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert("Student fee data deleted successfully!");
      } catch (error) {
        console.error("Error deleting data:", error);
        alert("Failed to delete student fee data.");
      }
    }
  };

  return (
    <Container
      fluid
      className=" p-4"
      style={{ backgroundColor: "#1E1E2E", minHeight: "210vh" }}
    >
      <nav
        aria-label="breadcrumb"
        className="text-white d-flex justify-content-between align-items-center"
        style={{
          fontSize: "1.5rem",
          fontWeight: 500,
          backgroundColor: "#1E1E2E",
        }}
      >
        <ol className="breadcrumb">
          <li className="breadcrumb-item text-white">Accounts</li>
          <li className="breadcrumb-item text-white"></li>

        </ol>
        <div className="d-flex align-items-center gap-3">
          {/* Search Bar */}
          <input
            type="text"
            className="form-control custom-placeholder"
            placeholder="Search"
            style={{
              width: "200px",
              backgroundColor: "#2C2C3C",
              border: "1px solid #444",
              color: "#FFFFFF",
            }}
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <style>
            {`
              .custom-placeholder::placeholder {
              color: rgba(255, 255, 255, 0.7); /* Light gray placeholder */
              }
            `}
          </style>
          {/* Custom Icons */}
          <img
            src={print}
            alt="Print"
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
            title="Print"
            onClick={handlePrint}
          />
          <img
            src={download}
            alt="Download"
            style={{
              width: "24px",
              height: "24px",
              cursor: "pointer",
            }}
            title="Download"
            onClick={handleDownloadExcel}
          />
          <img
            src={resize}
            alt="Fullscreen"
            style={{
              width: "20px",
              height: "20px",
              cursor: "pointer",
            }}
            title="Fullscreen"
            onClick={() => document.documentElement.requestFullscreen()}
          />
        </div>
      </nav>
      <Row>
        <Col>
          <div ref={printRef}>
            <Card style={{ borderColor: "#3E3E3E" }}>
              <Card.Body
                className="p-0"
                style={{ backgroundColor: "#2D2F3A" }}
              >
                <table
                  className="text-light"
                  style={{
                    borderCollapse: "collapse",
                    border: "none",
                    width: "100%",
                  }}
                >
                  <thead style={{ backgroundColor: '#3A3F51' }}>
                    <tr>
                      <td
                        className={`${Classes.table} text-start`}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#A0A0A0",
                          padding: "10px",
                        }}
                      >
                        Receipt ID
                      </td>
                      <td
                        className={`${Classes.table} text-start`}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#A0A0A0",
                          padding: "10px",
                        }}
                      >
                        Transaction ID
                      </td>
                      <td
                        className={`${Classes.table} text-start`}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#A0A0A0",
                          padding: "10px",
                          fontWeight: 500,
                        }}
                      >
                        Name
                      </td>
                      <td
                        className={`${Classes.table} text-start`}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#A0A0A0",
                          padding: "10px",
                          fontWeight: 500,
                        }}
                      >
                        Amount Paid
                      </td>
                      <td
                        className={`${Classes.table} text-start`}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#A0A0A0",
                          padding: "10px",
                          fontWeight: 500,
                        }}
                      >
                        Date
                      </td>
                      <td
                        className={`${Classes.table} text-start`}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#A0A0A0",
                          padding: "10px",
                          fontWeight: 500,
                        }}
                      >
                        Number
                      </td>
                      <td
                        className={`${Classes.table} text-start`}
                        style={{
                          backgroundColor: "#3A3F51",
                          color: "#A0A0A0",
                          padding: "10px",
                          fontWeight: 500,
                        }}
                      >
                        Email
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {currentData
                      // Only include items with a transaction ID
                      .map((item, index) => (
                        <tr key={index} style={{ borderBottom: "1px solid #3E3E3E" }}>
                          <td
                            className="text-start"
                            style={{ padding: "10px", color: "#EEEEEE" }}
                          >
                            {item.receipt_id}
                          </td>
                          <td
                            className="text-start"
                            style={{ padding: "10px", color: "#EEEEEE" }}
                          >
                            {item.transaction_id}
                          </td>
                          <td
                            className="text-start"
                            style={{ padding: "10px", color: "#EEEEEE" }}
                          >
                            {item.student_details.firstname}
                          </td>
                          <td
                            className="text-start"
                            style={{ padding: "10px", color: "#A0A0A0" }}
                          >
                            {item.fee_collected}
                          </td>
                          <td
                            className="text-start"
                            style={{ padding: "10px", color: "#A0A0A0" }}
                          >
                            {item.date_of_fee_collection}
                          </td>
                          <td
                            className="text-start"
                            style={{ padding: "10px", color: "#A0A0A0" }}
                          >
                            {item.student_details.phone}
                          </td>
                          <td

                            style={{ padding: "10px", color: "#A0A0A0" }}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="flex-start">
                                {item.student_details.email}
                              </div>
                              <div className="d-flex gap-4">
                                <img
                                  src={view}
                                  alt="view"
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    fontWeight: '700px',
                                    cursor: "pointer",
                                  }}
                                  title="View Receipt"
                                  onClick={() => handleImageClick(item.studentid, item.receipt_id)} // Call function on click
                                />
                                <img
                                  src={download}

                                  alt="Download"
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    cursor: "pointer",
                                  }}
                                  title="Download"
                                  onClick={handleDownloadExcel}
                                />
                                <img
                                  src={Delete}
                                  alt="Delete"
                                  style={{
                                    width: "24px",
                                    height: "24px",
                                    cursor: "pointer",
                                  }}
                                  title="Delete"
                                  onClick={() => handleDeleteRow(item.studentid, item.receipt_id)} // Use the field containing the actual ID
                                />
                              </div>
                            </div>

                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </Card.Body>
            </Card>
            {/* Pagination */}
            <div className="d-flex justify-content-between align-items-center mt-2">
              <Button
                variant="secondary"
                onClick={handlePrevPage}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <div className="d-flex justify-content-center mt-3">
                {[...Array(totalPages)].map((_, index) => (
                  <Button
                    key={index}
                    variant={currentPage === index + 1 ? '#000' : 'outline-primary'}
                    onClick={() => handlePageChange(index + 1)}
                    className="mx-1" // You can adjust this to "mx-0" for no horizontal margin
                    style={{
                      color: currentPage === index + 1 ? "#ffffff" : "#757575", // text color
                      backgroundColor: currentPage === index + 1 ? "#3A3F51" : "transparent", // background color
                      border: currentPage === index + 1 ? "none" : "1px solid #757575" // border color
                    }}
                  >
                    {index + 1}
                  </Button>
                ))}
              </div>
              <Button
                variant="secondary"
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default StudentData;
