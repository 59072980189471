import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import eSara from "../Assets/esara1.png";
import Logo from "../Assets/logo.png";
import msalInstance from "../msalConfig";
import axios from "axios";
import { useRef } from "react";

function EsaraPayment() {
  const [searchid, setSearchid] = useState("");
  const [studentData, setStudentData] = useState({
    studentid:"",
    firstname:"",
    group_name:"",
    class_name:"MBBS",
    fathersName:"",
    admissionno:"",
    dateofbirth:"",
    localphone:"",
    arrears:"",
  });
  const [collectedFee, setCollectedFee] = useState(null);
  const [pendingFeee, setPendingFeee] = useState(null);
  const [yearfee, setYearfee] = useState(null);
  const [yeartotal, setYeartotal] = useState(null);

  const [fee, setFee] = useState(null);

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        // Fetch the token
        const token = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"],
        });

        // Make the API request with the token
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/yearlyfee/${searchid}`,
          {
            headers: {
              Authorization: `Bearer ${token.idToken}`,
            },
          }
        );

        // Update state with the fetched data
        setCollectedFee(response.data.fee_collected);
        setFee(response.data.fee_to_be_collected);
        setYeartotal(response.data.total_fee);

        console.log(response.data);
        console.log(response.data.fee_to_be_collected);
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    };

    fetchStudentData();
  }, [searchid]);

  ///pendingfee
  useEffect(() => {
    const fetchData = async () => {
      try {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
          throw new Error("No accounts found");
        }
        msalInstance.setActiveAccount(accounts[0]);
        const token = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"],
        });
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/collectedfee/calculatePendingFee?student_id=${searchid}`,
          {
            headers: {
              authorization: `Bearer ${token.idToken}`,
            },
          }
        );
        setPendingFeee(response.data.pending);
        console.log("PendingFee:", response.data.pending);
        setCollectedFee(response.data.total_collected);
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    };

    fetchData();
  }, [searchid]);

  useEffect(() => {
    if (pendingFeee !== null) {
      console.log("PendingFee:", pendingFeee);
    }
  }, [pendingFeee]);

  const handleSearchChange = (event) => {
    setSearchid(event.target.value);
  };

  const handleSearchSubmit = async () => {
    if (searchid) {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        throw new Error("No accounts found");
      }
      msalInstance.setActiveAccount(accounts[0]);
      var token = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"],
      });
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent/${searchid}`,
          {
            headers: {
              Authorization: `Bearer ${token.idToken}`,
            },
          }
        )
        .then((response) => {
          setStudentData(response.data); // Set student data in state
          // Set the student ID in fees state
          setFees((prevFees) => ({
            ...prevFees,
            studentid: response.data.studentid, // Set student ID in fees state
          }));
        })
        .catch((error) => {
          console.error("Error fetching student data:", error);
        });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit();
    }
  };

  const [fees, setFees] = useState({
    year: "",
    firstsem: "",
    secondsem: "",
    vise: "",
    medical: "",
    hostel: "",
    mess: "",
    L_main: "",
    transp: "",
    fines: "",
    discount: "",
    total:"",
    transaction_number:"",
    
  });

  const [additionalFields, setAdditionalFields] = useState([]);


  const handleFeesChange = (e) => {
    const { name, value } = e.target;
    setFees((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


 const handleAdditionalFieldChange = (index, e) => {
  const { name, value } = e.target;
  setAdditionalFields((prevFields) => {
    const newFields = [...prevFields];
    newFields[index] = {
      ...newFields[index],
      [name]: value,
    };
    return newFields;
  });
};

const addField = () => {
  setAdditionalFields((prevFields) => [
    ...prevFields,
    {
      transaction_number: "",
      date_of_fee_collection: "",
      Account_type: "",
      fee_collected: 0,
    },
  ]);
};


  const calculateTotalFees = () => {
    let total = 0;

    // Add the main fees.fee_collected (ensure it is a number)
    total += Number(fees.fee_collected || 0);

    // Add all additional fields' fee_collected
    additionalFields.forEach((field) => {
      total += Number(field.fee_collected || 0);
    });

    return total;
  };

  const updatedata = async () => {
    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        throw new Error("No accounts found");
      }
      msalInstance.setActiveAccount(accounts[0]);
  
      const token = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"],
      });
  
      // Combine the main fees and additional fields
      const payload = {
        fees,
        additionalFields,
      };
  
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/esara/esara`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token.idToken}`,
          },
        }
      );
  
      console.log("Response:", response.data);
  
      // Persist total fees collected in state
      setYearfee(response.data.total_fee_collected);
  
      // Success notification
      alert("Submitted Successfully!");
    } catch (error) {
      console.error("Error updating data:", error.response?.data || error.message);
      alert("Submission Failed. Please try again.");
    }
  };
  

  function printContainer() {
    // Ensure the element exists
    var content = document.getElementById("printedpage");

    if (content) {
      console.log("Found printedpage element");

      // Clone the content to preserve styles
      var clone = content.cloneNode(true);

      // Copy inline styles of each element recursively
      function copyStyles(sourceElement, clonedElement) {
        const computedStyles = window.getComputedStyle(sourceElement);
        for (let property of computedStyles) {
          clonedElement.style[property] =
            computedStyles.getPropertyValue(property);
        }
        Array.from(sourceElement.children).forEach((child, index) => {
          copyStyles(child, clonedElement.children[index]);
        });
      }

      copyStyles(content, clone);

      // Create the print window
      var printWindow = window.open("", "", "height=600,width=800");
      console.log("Print window opened");

      // Add Bootstrap stylesheet to the print window and custom styles for printing
      printWindow.document.write(`
                <html>
                    <head>
                        <title>Print</title>
                        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" integrity="sha384-Mc5oqod23fJzlwfYUVB0KR5cvO7uEioyS7t27i/j4LLcyMFEnMRbK5a4Qz0QUJjZ" crossorigin="anonymous">
                        <style>
                            /* Custom styles for printing */
                            body {
                                margin: 0;
                                padding: 0;
                                overflow: hidden ; /* Hide scrollbars */

                            }
                            #printedpage {
                                width: 100%;
                                height: 200px;
                                overflow: hidden; /* Hide overflow inside printed content */
                                page-break-before: always;
                            }
                            @media print {
                                body {
                                    overflow: hidden !important;
                                    margin: 0;
                                    padding: 0;
                                }
                                #printedpage {
                                    width: 100%;
                                    height: auto;
                                    overflow: hidden;
                                }
                                .container {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                            /* Optional: Additional styles for print */
                            @page {
                                margin: 0;
                            }
                        </style>
                    </head>
                    <body>
                        ${clone.outerHTML}
                    </body>
                </html>
            `);
      printWindow.document.close();
      printWindow.print();
      console.log("Printed");
    } else {
      console.error('Element with id "printedpage" not found');
    }
  }

  return (
    <div
      className="container-fluid min-vh-100 d-flex align-items-start justify-content-center"
      style={{ backgroundColor: "#1E1E2E" }}
    >
      <div className="row w-100">
        <div className="col p-0">
          <nav
            style={{
              "--bs-breadcrumb-divider": `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E")`,
              fontSize: "1.5rem",
              fontWeight: 500,
              backgroundColor: "#1E1E2E",
            }}
            aria-label="breadcrumb m-3"
            className="p-3 text-white d-flex justify-content-between align-items-center"
          >
            <ol className="breadcrumb m-2">
              <li className="breadcrumb-item text-white">Student Admit</li>
              <li className="breadcrumb-item active" aria-current="page"></li>
            </ol>
            <div className="d-flex align-items-center gap-3">
              {/* Search Bar */}
              <div className="input-group">
                <input
                  type="text"
                  className="form-control custom-placeholder"
                  placeholder="Enter Student ID"
                  style={{
                    width: "200px",
                    backgroundColor: "#2C2C3C",
                    border: "1px solid #444",
                    color: "#FFFFFF",
                  }}
                  aria-label="Search"
                  value={searchid}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <style>
                {`
              .custom-placeholder::placeholder {
              color: rgba(255, 255, 255, 0.7); /* Light gray placeholder */
              }
            `}
              </style>
            </div>
          </nav>
          <div
            className="mx-auto col-md-12 mb-5 d-flex row justify-content-center"
            style={{
              backgroundColor: "#2D2F3A",
              borderRadius: "8px",
              padding: "25px",
            }}
          >
            <div
              className="container w-100 p-10"
              id="printedpage"
              style={{ backgroundColor: "#FFFFFF" }}
            >
              <div
                className="container w-100"
                style={{
                  background:
                    "linear-gradient(to right, #002666 0%, #2E5ACB 100%)",
                }}
              >
                <div className="row align-items-center flex-nowrap">
                  <div className="col-3 col-md-2 d-flex justify-content-start">
                    <img
                      src={eSara}
                      alt="eSaraLogo"
                      style={{ maxWidth: "100%" }}
                    />
                  </div>
                  <div className="col-5 col-md-6 d-flex align-items-center">
                    <h2
                      className="mb-0 text-white"
                      style={{ fontSize: "clamp(20px, 4vw, 32px)" }}
                    >
                      eSara
                    </h2>
                  </div>
                  <div className="col-4 col-md-4 d-flex flex-column align-items-end text-white">
                    <div className="d-flex flex-column align-items-center">
                      <h5
                        className="mb-1"
                        style={{ fontSize: "clamp(14px, 2.5vw, 24px)" }}
                      >
                        Ledger Account
                      </h5>
                      <h6
                        className="mb-1"
                        style={{ fontSize: "clamp(12px, 2vw, 20px)" }}
                      >
                        2024-25
                      </h6>
                      <h6
                        className="mb-1"
                        style={{ fontSize: "clamp(12px, 2vw, 20px)" }}
                      >
                        Payment Due
                      </h6>
                      <h6
                        className="mb-1"
                        style={{
                          color: "#FF4848",
                          fontSize: "clamp(12px, 2vw, 20px)",
                        }}
                      >
                        {pendingFeee}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container w-100 m-0">
                <div className="row align-items-center mt-1">
                  <div className="col-6 d-flex justify-content-start">
                    <h2 style={{ fontSize: "clamp(18px, 3vw, 28px)" }}>
                      Asia International University - UBZ
                    </h2>
                  </div>
                  <div className="col-6 d-flex justify-content-end align-items-center">
                    <img src={Logo} alt="eSaraLogo" style={{ maxWidth: "100%", height: "auto" }} />
                    <h6 className="mb-0 ms-2" style={{ color: "#D80303", fontSize: "clamp(12px, 1.8vw, 16px)",}}>2024-25</h6>
                  </div>
                </div>
              </div>
              <Container className="p-3" style={{ backgroundColor: "#FFFFFF" }}>
                <Table bordered responsive className="mb-1" style={{ tableLayout: "fixed", width: "100%", borderColor: "#333333", }}>
                  <thead>
                    <tr>
                      <td>Class Name</td>
                      <td><input type="text" name="class_name" value={studentData.class_name} style={{ width: "100%", border: "none" }} /></td>
                      <td>Name</td>
                      <td colSpan={5}><input type="text" name="firstname" value={studentData.firstname} onChange={handleFeesChange} style={{ width: "100%", border: "none" }} /></td>
                    </tr>
                    <tr>
                      <td>Group</td>
                      <td><input type="text" name="group_name" value={studentData.group_name} onChange={handleFeesChange} style={{ width: "100%", border: "none" }} /> </td>
                      <td>Father Name</td>
                      <td colSpan={5}> <input type="text" name="fathersName" value={studentData.fathersName} onChange={handleFeesChange} style={{ width: "100%", border: "none" }} />
                      </td>
                    </tr>
                    <tr>
                      <td>Admission</td>
                      <td><input type="text" name="admissionno" value={studentData.admissionno} onChange={handleFeesChange} style={{ width: "100%", border: "none" }} /></td>
                      <td>Student ID</td>
                      <td><input type="text" name="studentid" value={studentData.studentid} onChange={handleFeesChange} style={{ width: "100%", border: "none" }} /></td>
                      <td style={{ textAlign: "center" }}>DOB</td>
                      <td><input type="text" name="dateofbirth" value={studentData.dateofbirth} onChange={handleFeesChange} style={{ width: "100%", border: "none" }} /></td>
                      <td style={{ textAlign: "center" }}>P.No</td>
                      <td><input type="text" name="localphone" value={studentData.localphone} onChange={handleFeesChange} style={{ width: "100%", border: "none" }} /></td>
                    </tr>
                  </thead>
                </Table>
                <div className="d-flex justify-content-end mb-1">
                  <Table className="table table-bordered" style={{ tableLayout: "auto", width: "35%", borderColor: "#333333", }}>
                    <tbody>
                      <tr>
                        <td style={{ width: "28%", color: "#D80303", textAlign: "center", fontWeight: "bold", }}>Arrears</td>
                        <td><input type="text" name="arrears" value={studentData.arrears} onChange={handleFeesChange} style={{ width: "100%", border: "none" }} /></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                <Table bordered responsive className="mb-1" style={{ borderColor: "#333333" }}>
                  <thead>
                    <tr>
                      <td>Year</td>
                      <td style={{ textAlign: "center" }}>1st Sem</td>
                      <td style={{ textAlign: "center" }}>Visa</td>
                      <td style={{ textAlign: "center" }}>2nd Sem</td>
                      <td style={{ textAlign: "center" }}>Medical</td>
                      <td style={{ textAlign: "center" }}>Hostel</td>
                      <td style={{ textAlign: "center" }}>Mess</td>
                      <td style={{ textAlign: "center" }}>L/Main</td>
                      <td style={{ textAlign: "center" }}>TransP</td>
                      <td style={{ textAlign: "center" }}>Fine</td>
                      <td style={{ textAlign: "center" }}>Disc</td>
                      <td style={{ textAlign: "center" }}>Total</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <select name="year" 
                        value={fees.year}
                          onChange={(e) => {
                            const selectedYear = parseInt(e.target.value, 10); // Convert the value to an integer
                            setFees((prevFees) => ({
                              ...prevFees,
                              year: selectedYear,
                            }));
                          }}
                          style={{ width: "100%", padding: "5px", border: "1px solid #ccc", }}>
                          <option value={0}>Select Year</option>
                          <option value={1}>I</option>
                          <option value={2}>II</option>
                          <option value={1}>III</option>
                          <option value={2}>IV</option>
                          <option value={1}>V</option>
                        </select>
                      </td>
                      <td><input type="text" inputMode="numeric" value={yeartotal} onChange={(e) => handleFeesChange(e, 0)} name="firstsem" style={{ width: "100%", border: "none" }} /></td>
                      <td><input type="text" inputMode="numeric" value={fees.vise} onChange={(e) => handleFeesChange(e, 0)} name="vise" style={{ width: "100%", border: "none" }} /></td>
                      <td><input type="text" inputMode="numeric" value={fees.secondsem} onChange={(e) => handleFeesChange(e, 0)} name="secondsem" style={{ width: "100%", border: "none" }} /></td>
                      <td><input type="text" inputMode="numeric" value={fees.medical} onChange={(e) => handleFeesChange(e, 0)} name="medical" style={{ width: "100%", border: "none" }} /></td>
                      <td><input type="text" inputMode="numeric" value={fees.hostel} onChange={(e) => handleFeesChange(e, 0)} name="hostel" style={{ width: "100%", border: "none" }} /></td>
                      <td><input type="text" inputMode="numeric" value={fees.mess} onChange={(e) => handleFeesChange(e, 0)} name="mess" style={{ width: "100%", border: "none" }} /></td>
                      <td><input type="text" inputMode="numeric" value={fees.L_main} onChange={(e) => handleFeesChange(e, 0)} name="L_main" style={{ width: "100%", border: "none" }} /></td>
                      <td><input type="text" inputMode="numeric" value={fees.transp} onChange={(e) => handleFeesChange(e, 0)} name="transp" style={{ width: "100%", border: "none" }} /></td>
                      <td><input type="text" inputMode="numeric" value={fees.fines} onChange={(e) => handleFeesChange(e, 0)} name="fines" style={{ width: "100%", border: "none" }} /></td>
                      <td><input type="text" inputMode="numeric" value={fees.discount} onChange={(e) => handleFeesChange(e, 0)} name="discount" style={{ width: "100%", border: "none" }} /></td>
                      <td><input type="text" value={yeartotal} name="total" readOnly style={{ width: "100%", border: "none", backgroundColor: "#f0f0f0", }} /></td>
                    </tr>
                  </tbody>
                </Table>
                <Table className="table table-bordered mb-1" style={{ tableLayout: "auto", width: "100%", borderColor: "#333333", }}>
                  <tbody>
                    <tr>
                      <td style={{ width: "28%" }}>Total Slip Amount</td>
                      <td> <input type="text" name="" value={yeartotal} readOnly style={{ width: "100%", border: "none", backgroundColor: "#f0f0f0", }} /></td>
                    </tr>
                  </tbody>
                </Table>
                {/* Total Slip Amount Section */}
                <Table bordered responsive className="mb-1" style={{ borderColor: "#333333" }}>
                  <tbody>
                    <tr>
                      <td>Transaction Number</td>
                      <td style={{ textAlign: "center" }}>Transaction Date</td>
                      <td style={{ textAlign: "center" }}>Account Type</td>
                      <td style={{ textAlign: "center" }}>Amount Received</td>
                    </tr>
                    <tr>
                      <td><input type="text" name="transaction_id" value={fees.transaction_id} onChange={handleFeesChange} style={{ width: "100%", border: "none" }} /></td>
                      <td><input type="date" name="date_of_fee_collection" onChange={handleFeesChange} style={{ width: "100%", border: "none" }} /></td>
                      <td><input type="text" name="account_type" value={fees.Account_type} onChange={handleFeesChange} style={{ width: "100%", border: "none" }} /></td>
                      <td><input type="text" value={fees.fee_collected} onChange={handleFeesChange} name="fee_collected" style={{ width: "100%", border: "none" }} /></td>
                    </tr>
                    {additionalFields.map((field, index) => (
                      <tr key={index}>
                        <td><input type="text" name="transaction_id" value={field.transaction_id} onChange={(e) => handleAdditionalFieldChange(index, e)} style={{ width: "100%", border: "none" }} /></td>
                        <td><input type="date" name="date_of_fee_collection" value={field.date_of_fee_collection} onChange={(e) => handleAdditionalFieldChange(index, e)} style={{ width: "100%", border: "none" }} /></td>
                        <td><input type="text" name="account_type" value={field.account_type} onChange={(e) => handleAdditionalFieldChange(index, e)} style={{ width: "100%", border: "none" }} /></td>
                        <td><input type="text" name="fee_collected" value={field.fee_collected} onChange={(e) => handleAdditionalFieldChange(index, e)} style={{ width: "100%", border: "none" }} /></td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-end mb-2">
                  <button className="rounded" style={{ backgroundColor: "#333333", color: "#E0E0E0" }} onClick={addField}> Add Field</button>
                </div>
                <Table className="table table-bordered mb-1" style={{ tableLayout: "auto", width: "100%", borderColor: "#333333", }}>
                  <tbody>
                    <tr>
                      <td style={{ width: "28%" }}>Total Receive Amount</td>
                      <td> <input type="text" value={fees.fee_collected} onChange={handleFeesChange} name="fee_collected" style={{ width: "100%", border: "none" }} /></td>
                    </tr>
                  </tbody>
                </Table>
                <div className="d-flex justify-content-between mb-1">
                  <div style={{ width: "50%" }}>
                    <div>
                      <Table bordered style={{ width: "100%", borderColor: "#333333" }} className="mb-1" >
                        <tbody>
                          <tr>
                            <td style={{ width: "40%" }}>Total Slip Amount</td>
                            <td><input type="text" value={yeartotal} readOnly style={{ width: "100%", border: "none", backgroundColor: "#f0f0f0", }} /></td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div>
                      <Table bordered style={{ width: "100%", borderColor: "#333333" }}>
                        <tbody>
                          <tr>
                            <td style={{ width: "40%" }}>Total Received Amount</td>
                            <td><input type="text" value={calculateTotalFees()} readOnly style={{ width: "100%", border: "none" }} /></td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="mt-4" style={{ width: "40%" }}>
                    <Table bordered style={{ width: "100%", borderColor: "#333333" }}>
                      <tbody>
                        <tr>
                          <td style={{ width: "40%", color: "#FF0000", fontWeight: "bold", textAlign: "center", }}>Current Balance</td>
                          <td><input type="text" inputMode="numeric" name="currentbalance" value={pendingFeee} style={{ width: "100%", textAlign: "center", border: "none", }} /></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Container>
              <Container fluid className="p-4">
                <Row>
                  <Col>
                    <div style={{ background: "linear-gradient(to right, #002666 0%, #2E5ACB 100%)", color: "#ffffff", padding: "10px", textAlign: "center", fontWeight: "bold", }}>
                      The above mentioned amount once paid are not-refundable
                      under any circumstances.
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="d-flex justify-content-center gap-3" style={{ padding: "20px 0" }}>
              <button type="button" className="btn btn-primary">Save</button>
              <button type="button" className="btn btn-primary" onClick={printContainer}  style={{ color: "#EEEEEE", borderColor: "rgba(41, 112, 255, 1)", }}> Print</button>
              <button type="button" className="btn btn-primary" onClick={updatedata} style={{ color: "#EEEEEE",  borderColor: "rgba(41, 112, 255, 1)"}}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EsaraPayment;
