import React, { useState, useEffect } from "react";
import axios from "axios";
import classes from "./Billing.module.css";
import collegefee from "../Assets/collegefee.png";
import messfee from '../Assets/messfee.png';
import overall from '../Assets/overall.png';
import { useLocation } from "react-router-dom"; // Hook to access passed state

const IncomeDataForm = () => {
  const location = useLocation(); // Get state passed from the income page
  const [formData, setFormData] = useState({
    Paid_to_University_Fee_by_Esara: "",
    Hostel_Expense: "",
    Mess_Expense: "",
    Transportation: "",
    Miscellaneous_Esara_Uni_Expense: "",
    Miscellaneous_Uzbekistan_Esara_Expense: "",
    Miscellaneous_Esara_India_Expense: "",
    Miscellaneous_Esara_Nepal_Expense: "",
    Undefined_Expense_in_Uzbekistan: "",
    Other_expenses: "",
  });

  const [totalExpenses, setTotalExpenses] = useState(0);
  const [remainingBalance, setRemainingBalance] = useState(0);

  const totalIncome = location.state?.totalIncome || 0; // Access totalIncome

  useEffect(() => {
    setRemainingBalance(totalIncome - totalExpenses);
  }, [totalIncome, totalExpenses]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Update formData state
    setFormData((prev) => {
      const updatedData = { ...prev, [name]: value };

      // Calculate the total expense every time the formData changes
      const updatedTotalExpenses = Object.values(updatedData).reduce(
        (sum, val) => sum + (parseFloat(val) || 0),
        0
      );
      setTotalExpenses(updatedTotalExpenses); // Update the total expense state

      return updatedData;
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Log the formData to ensure all values are captured
    console.log("Form Data before submission:", formData);

    const apiEndpoint = `${process.env.REACT_APP_BASE_URL}/api/v1/adminpanel/expence`; // Use Expense API endpoint

    try {
      const payload = {
        Paid_to_university_fee_by_esara: parseFloat(formData.Paid_to_University_Fee_by_Esara) || 0,
        Hostel_expenses: parseFloat(formData.Hostel_Expense) || 0,
        Mess_expenses: parseFloat(formData.Mess_Expense) || 0,
        Transportation: parseFloat(formData.Transportation) || 0,
        Miscellaneous_esara_uni_expenses: parseFloat(formData.Miscellaneous_Esara_Uni_Expense) || 0,
        Miscellaneous_uzbekistan_esara_expense: parseFloat(formData.Miscellaneous_Uzbekistan_Esara_Expense) || 0,
        Miscellaneous_esara_india_expense: parseFloat(formData.Miscellaneous_Esara_India_Expense) || 0,
        Miscellaneous_esara_nepal_expense: parseFloat(formData.Miscellaneous_Esara_Nepal_Expense) || 0,
        Undefined_expense_in_uzbekistan: parseFloat(formData.Undefined_Expense_in_Uzbekistan) || 0,
        Other_expenses: parseFloat(formData.Other_expenses) || 0,
      };

      // Log the payload to ensure data formatting is correct
      console.log("Payload being sent:", payload);

      const response = await axios.post(apiEndpoint, payload);
      alert("Expense data submitted successfully!");
      console.log("Response:", response.data);

      // Reset form after successful submission
      setFormData({
        Paid_to_University_Fee_by_Esara: "",
        Hostel_Expense: "",
        Mess_Expense: "",
        Transportation: "",
        Miscellaneous_Esara_Uni_Expense: "",
        Miscellaneous_Uzbekistan_Esara_Expense: "",
        Miscellaneous_Esara_India_Expense: "",
        Miscellaneous_Esara_Nepal_Expense: "",
        Undefined_Expense_in_Uzbekistan: "",
        Other_expenses: "",
      });
    } catch (error) {
      console.error("Error submitting expense data:", error);
      alert("Failed to submit expense data.");
    }
  };

  return (
    <div className="row col-13 d-flex justify-content-center gap-7" style={{ backgroundColor: '#1E1E2E', minHeight: '100vh', }}>
      <nav
        style={{
          "--bs-breadcrumb-divider": `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E")`, // Custom breadcrumb divider
          fontSize: '1.5rem',
          fontWeight: 500,
          backgroundColor: '#1E1E2E'
        }}
        aria-label="breadcrumb"
        className="p-3 text-white"
      >
        <ol className="breadcrumb m-2">
          <li className="breadcrumb-item text-white">Expense Data</li>
          <li className="breadcrumb-item active" aria-current="page"></li>
        </ol>
      </nav>
      <div className="col-lg-7 mb-5">
        <div
          style={{
            padding: "20px",
            background: "#2D2F3A",
            color: '#EEEEEE',
            borderRadius: "8px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <form onSubmit={handleSubmit}>
            {[{
              label: "Paid to University Fee by Esara",
              name: "Paid_to_University_Fee_by_Esara"
            },
            {
              label: "Hostel Expense",
              name: "Hostel_Expense"
            },
            {
              label: "Mess Expense",
              name: "Mess_Expense"
            },
            {
              label: "Transportation",
              name: "Transportation"
            },
            {
              label: "Miscellaneous Esara - Uni Expense",
              name: "Miscellaneous_Esara_Uni_Expense"
            },
            {
              label: "Miscellaneous Uzbekistan Esara Expense",
              name: "Miscellaneous_Uzbekistan_Esara_Expense"
            },
            {
              label: "Miscellaneous Esara India Expense",
              name: "Miscellaneous_Esara_India_Expense"
            },
            {
              label: "Miscellaneous Esara Nepal Expense",
              name: "Miscellaneous_Esara_Nepal_Expense"
            },
            {
              label: "Undefined Expense in Uzbekistan",
              name: "Undefined_Expense_in_Uzbekistan"
            },
            {
              label: "Other Expenses",
              name: "Other_expenses"
            }].map((field) => (
              <div key={field.name} style={{ marginBottom: "15px" }}>
                <label
                  htmlFor={field.name}
                  style={{ fontWeight: "600", display: "block", marginBottom: "5px" }}
                >
                  {field.label}
                </label>
                <input
                  id={field.name}
                  name={field.name}
                  type="text"
                  placeholder="Enter amount"
                  value={formData[field.name]}
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    padding: "10px",
                    borderRadius: "4px",
                    backgroundColor: '#3A3F51',
                    color: 'white'
                  }}
                />
              </div>
            ))}
            <div style={{ textAlign: 'right' }}>
              <button
                type="submit"
                style={{
                  width: "20%",
                  padding: "10px",
                  background: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  fontWeight: "600",
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="col-lg-4">
        <div
          className="col-lg-3 col-md-6 col-sm-12 p-3 d-flex justify-content-between align-items-center rounded-2"
          style={{
            width: "360px",
            height: "100px",
            backgroundColor: "rgba(45, 47, 58, 1)",
          }}
        >
          <div>
            <span className={classes.cardstitles} style={{ fontWeight: 'bold' }}>
              Summary
            </span>
          </div>
        </div>
        <div
          className="col-lg-3 col-md-6 col-sm-12 p-3 mt-3 d-flex justify-content-between align-items-center rounded-2"
          style={{
            width: "360px",
            height: "100px",
            backgroundColor: "rgba(45, 47, 58, 1)",
          }}
        >
          <div>
            <span className={classes.cardstitles} style={{ fontWeight: 'bold' }}>
              Total Income
            </span>
            <br />
            <span className={classes.cardsnumber}>{totalIncome}</span>
          </div>
          <img src={collegefee} width={40} height={40} alt="totalIncome" />
        </div>
        <div
          className="col-lg-3 col-md-6 col-sm-12 p-3 mt-3 d-flex justify-content-between align-items-center rounded-2"
          style={{
            width: "360px",
            height: "100px",
            backgroundColor: "rgba(45, 47, 58, 1)",
          }}
        >
          <div>
            <span className={classes.cardstitles} style={{ fontWeight: 'bold' }}>
              Total Expenses
            </span>
            <br />
            <span className={classes.cardsnumber}>{totalExpenses}</span>
          </div>
          <img src={collegefee} width={40} height={40} alt="totalExpenses" />
        </div>
        <div
          className="col-lg-3 col-md-6 col-sm-12 p-3 mt-3 d-flex justify-content-between align-items-center rounded-2"
          style={{
            width: "360px",
            height: "100px",
            backgroundColor: "rgba(45, 47, 58, 1)",
          }}
        >
          <div>
            <span className={classes.cardstitles} style={{ fontWeight: 'bold' }}>
              Remaining Balance
            </span>
            <br />
            <span className={classes.cardsnumber}>{remainingBalance}</span>
          </div>
          <img src={collegefee} width={40} height={40} alt="remainingBalance" />
        </div>
      </div>
    </div>
  );
};

export default IncomeDataForm;
