import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Hook to access passed state

const IncomeDataForm = () => {
  const [formData, setFormData] = useState({
    Paid_by_student: "",
    Paid_by_agent: "",
    Paid_in_university_account: "",
    Paid_in_nepal_account: "",
    Paid_in_india_delhi_account: "",
    Paid_in_Esara_Uzbekistan_account: "",
    Other_income: "",
  });

  const navigate = useNavigate(); // Hook for navigation
  const [totalIncome, setTotalIncome] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Calculate total income before submission
    const totalIncome = Object.values(formData).reduce(
      (sum, value) => sum + (parseFloat(value) || 0),
      0
    );
    setTotalIncome(totalIncome); // Update the total income state
    const apiEndpoint = `${process.env.REACT_APP_BASE_URL}/api/v1/adminpanel/income`;

    try {
      const payload = {
        Paid_by_student: parseFloat(formData.Paid_by_student) || 0,
        Paid_by_agent: parseFloat(formData.Paid_by_agent) || 0,
        Paid_in_university_account: parseFloat(formData.Paid_in_university_account) || 0,
        Paid_in_nepal_account: parseFloat(formData.Paid_in_nepal_account) || 0,
        Paid_in_india_delhi_account: parseFloat(formData.Paid_in_india_delhi_account) || 0,
        Paid_in_Esara_Uzbekistan_account: parseFloat(formData.Paid_in_Esara_Uzbekistan_account) || 0,
        Other_income: parseFloat(formData.Other_income) || 0,
      };

      const response = await axios.post(apiEndpoint, payload);
      alert("Income data submitted successfully!");
      console.log("Response:", response.data);

      // Reset form after successful submission
      setFormData({
        Paid_by_student: "",
        Paid_by_agent: "",
        Paid_in_university_account: "",
        Paid_in_nepal_account: "",
        Paid_in_india_delhi_account: "",
        Paid_in_Esara_Uzbekistan_account: "",
        Other_income: "",
      });
      // Redirect to expenses page and pass totalIncome as state
      navigate("/app/expence", { state: { totalIncome } });
    } catch (error) {
      console.error("Error submitting income data:", error);
      alert("Failed to submit income data.");
    }
  };

  return (
    <div style={{  backgroundColor: '#1E1E2E', minHeight: '100vh',}}>
      <nav
          style={{
            "--bs-breadcrumb-divider": `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E")`, // Custom breadcrumb divider
            fontSize: '1.5rem',
            fontWeight: 500,
            backgroundColor: '#1E1E2E'
          }}
          aria-label="breadcrumb"
          className="p-3 text-white"
        >
          <ol className="breadcrumb m-2">
            <li className="breadcrumb-item text-white">Income Data</li>
            <li className="breadcrumb-item active" aria-current="page"></li>
          </ol>
        </nav>
      <div
        style={{
          width: "893.9px",
          padding: "40px",
          background: "#2D2F3A",
          color: '#EEEEEE',
          borderRadius: "8px",
          margin:'auto auto',
        }}
      >
        <form onSubmit={handleSubmit}>
          {[
            { label: "Paid by Student", name: "Paid_by_student" },
            { label: "Paid by Agent", name: "Paid_by_agent" },
            { label: "Paid in University Account", name: "Paid_in_university_account" },
            { label: "Paid in Nepal Account", name: "Paid_in_nepal_account" },
            { label: "Paid in India Delhi Account", name: "Paid_in_india_delhi_account" },
            { label: "Paid in Esara Uzbekistan Account", name: "Paid_in_Esara_Uzbekistan_account" },
            { label: "Other Income", name: "Other_income" },
          ].map((field) => (
            <div key={field.name} style={{ marginBottom: "15px" }}>
              <label
                htmlFor={field.name}
                style={{ fontWeight: "600", display: "block", marginBottom: "10px" }}
              >
                {field.label}
              </label>
              <input
                id={field.name}
                name={field.name}
                type="text"
                inputMode="numeric"
                value={formData[field.name]}
                onChange={handleChange}
                style={{
                  width: "100%",
                  padding: "10px",
                  borderRadius: "4px",
                  backgroundColor: '#3A3F51',
                  color: 'white'
                }}
              />
            </div>
          ))}
          <div style={{textAlign:'right'}}>
            <button
              className="btn btn-primary"
              type="submit"
              style={{
                width: "20%",
                padding: "10px",
                color: "#fff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                fontWeight: "600",
                marginTop: '15px'
              }}
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IncomeDataForm;
