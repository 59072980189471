import { Container,Table } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom"; // Import the useParams hook
import axios from "axios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import download from '../Assets/download.png';
import resize from '../Assets/resize.png';
import print from '../Assets/print.png';
import msalInstance from "../msalConfig";

function BillingRecipt() {

    const { studentid, receiptid } = useParams(); // Use useParams to get the route parameter
    const [studentData, setStudentData] = useState({});
    const [collectedFee, setCollectedFee] = useState('');
    const [dateoffeecollection, setDateoffeecollection] = useState('');
    const [pendingFee, setPendingFee] = useState('');



    useEffect(() => {
        const fetchData = async () => {
            try {
                const accounts = msalInstance.getAllAccounts();
                if (accounts.length === 0) {
                    throw new Error("No accounts found");
                }
                msalInstance.setActiveAccount(accounts[0]);
                const token = await msalInstance.acquireTokenSilent({
                    scopes: ["user.read"]
                });
                // Using Promise.all to fetch all the data concurrently
                const [studentResponse, collectedFeeResponse, pendingFeeResponse] = await Promise.all([
                    axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent/${studentid}`, {
                        headers: {
                            Authorization: `Bearer ${token.idToken}`
                        }
                    }),
                    axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/collectedfee`,
                        {
                            headers: {
                                Authorization: `Bearer ${token.idToken}`
                            }
                        }
                    ),
                    axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/collectedfee/calculatePendingFee?student_id=${studentid}`, {
                        headers: {
                            Authorization: `Bearer ${token.idToken}`
                        }
                    })
                ]);

                // Filter the data by receipt_id
                const filteredData = collectedFeeResponse.data.find((item) => item.receipt_id === receiptid);

                // Set the collected fee if found
                setCollectedFee(filteredData ? filteredData.fee_collected : "Not Found");
                // Set the state with fetched data
                setStudentData(studentResponse.data);


                setDateoffeecollection(filteredData ? filteredData.date_of_fee_collection : "Not Found");

                setPendingFee(pendingFeeResponse.data.pending_fee || ''); // Ensure correct field path

                console.log("Student Data:", studentResponse.data);
                console.log("Collected Fee Data:", collectedFeeResponse.data);

                console.log("Pending Fee Response:", pendingFeeResponse);
            } catch (err) {
                console.error("Error fetching data:", err);
            }
        };

        fetchData();
    }, [studentid, receiptid]);

    const receiptRef = useRef(null); // Reference for the receipt content

    const handlePrint = () => {
        const printContent = receiptRef.current.innerHTML; // Get the receipt content
        const printWindow = window.open("", "_blank"); // Open a new blank window
        printWindow.document.write(`
          <html>
            <head>
              <title>Print Receipt</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                  margin: 0;
                  padding: 20px;
                  background-color: #fff;
                  color: #000;
                }
                table {
                  width: 100%;
                  border-collapse: collapse;
                }
                td, th {
                  border: 1px solid #000;
                  padding: 8px;
                  text-align: left;
                }
              </style>
            </head>
            <body>
              ${printContent} <!-- Insert the receipt content -->
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.print(); // Trigger print
    };

    const handleDownloadPDF = async () => {
        const receiptElement = receiptRef.current; // Reference to the receipt content
        const canvas = await html2canvas(receiptElement, { scale: 3 }); // Render the receipt to a canvas

        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        // Calculate scaling ratio
        const ratio = Math.min((pageWidth - 10) / imgWidth, (pageHeight - 10) / imgHeight); // Subtract 10 to add padding

        const width = imgWidth * ratio;
        const height = imgHeight * ratio;

        const marginX = (pageWidth - width) / 2;
        const marginY = (pageHeight - height) / 2;

        pdf.addImage(imgData, "PNG", marginX, marginY, width, height);
        pdf.save("receipt.pdf");
    };

    return (
        <Container
            fluid
            className="vh-100 p-4"
            style={{ backgroundColor: "#1E1E2E", minHeight: "210vh" }}
        >
            <nav
                aria-label="breadcrumb"
                className="text-white d-flex justify-content-between align-items-center"
                style={{
                    fontSize: "1.5rem",
                    fontWeight: 500,
                    backgroundColor: "#1E1E2E",
                }}
            >
                <ol className="breadcrumb">
                    <li className="breadcrumb-item text-white">Receipt</li>
                    <li className="breadcrumb-item text-white"></li>

                </ol>
                <div className="d-flex align-items-center gap-3">
                    <img
                        src={print}
                        alt="Print"
                        style={{
                            width: "24px",
                            height: "24px",
                            cursor: "pointer",
                        }}
                        title="Print"
                        onClick={handlePrint}
                    />
                    <img
                        src={download}
                        alt="Download"
                        style={{
                            width: "24px",
                            height: "24px",
                            cursor: "pointer",
                        }}
                        title="Download"
                        onClick={handleDownloadPDF}
                    />
                </div>
            </nav>
            <div className="container row mx-auto mb-5 gap-2 justify-content-center" >
                <div className="col-lg-12">
                    <div>
                        <div
                            style={{
                                backgroundColor: 'rgba(37, 40, 54, 1)',
                                color: 'rgba(228, 228, 231, 1)',
                                borderRadius: '8px',
                                padding: '25px',
                            }}
                            className=" col-md-12 d-flex row justify-content-center"

                        >
                            <div className="container col-md-9 p-3 text-dark" style={{ backgroundColor: '#FFFFFF' }} ref={receiptRef}>
                                <Container className="p-4">
                                    <Table responsive className='mb-1' style={{ tableLayout: 'fixed', width: '100%', border: '1px solid #333333', padding: '2px' }}>
                                        <tbody >
                                            <tr style={{ textAlign: 'center' }} >
                                                <td style={{ borderRight: '1px solid #333333' }}>
                                                    <p style={{ margin: 0 }}>T o lov k o n t r a k t asosida mutaxassis</p>
                                                    <p style={{ margin: 0 }}>tayyorlash to'g'risida</p>
                                                    <p style={{ margin: 0 }}>SHARTNOMAN°131124</p>
                                                </td>
                                                <td style={{ borderRight: '1px solid #333333' }}>
                                                    <p style={{ margin: 0 }}>Payment for Specialist training</p>
                                                    <p style={{ margin: 0 }}>on the basis of a</p>
                                                    <p style={{ margin: 0 }}>CONTRACT No 131133</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ borderRight: '1px solid #333333' }}>
                                                    <p>Buxoro City</p>
                                                </td>
                                                <td style={{ borderRight: '1px solid #333333' }}>
                                                    <p>Bukhara City</p>
                                                </td>
                                            </tr>
                                            <tr style={{ textAlign: 'center' }}>
                                                <td style={{ borderRight: '1px solid #333333' }}>
                                                    <p>TOMONLARNING BANK REKVIZITLARI</p>
                                                </td>
                                                <td style={{ borderRight: '1px solid #333333' }}>
                                                    <p>BANK DETAILS, ADDRESSES</p>
                                                </td>
                                            </tr>
                                            <tr style={{ textAlign: 'center' }}>
                                                <td style={{ borderRight: '1px solid #333333' }}>
                                                    <p style={{ margin: 0 }}>"OSIYO XALQARO UNIVERSITETI"</p>
                                                    <p style={{ margin: 0 }}>MChj</p>
                                                </td>
                                                <td style={{ borderRight: '1px solid #333333' }}>
                                                    <p>Student and fees payment</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ borderRight: '1px solid #333333' }}>
                                                    <address>
                                                        <strong>Manzil:</strong> O'zbekiston Respublikasi Buxoro<br />
                                                        viloyati Buxoro shahri Yangiobod MFY,<br />
                                                        G'ijduvon ko'chasi 4-uy.<br />
                                                        telefon: +998553050009.
                                                    </address><br />
                                                    <address>
                                                        <strong>Bank rekvizitlari: SO'MDA (Local sum account) inside Uzbekistan</strong><br />
                                                        Ipoteka bank ATIB Buxoro viloyat filiali<br />
                                                        H/r: 20208000605567237002, MFO: 00088<br />
                                                        STIR: 309855582, IFUT: 85 420
                                                    </address><br />
                                                    <address>
                                                        <strong>Bank rekvizitlari: XORIJIY VALYUTADA<br />
                                                            (USD account) Outside Uzbekistan<br />
                                                            "ASIA ALLIANCE BANK"</strong><br />
                                                        H/r: 20208840105567237002, MFO: 01095<br />
                                                        SWIFT: ASACUZ22,<br />
                                                        <strong>STIR: 309855582,IFUT:85420</strong>
                                                    </address>
                                                </td>
                                                <td style={{ borderRight: '1px solid #333333' }}>
                                                    <div className="d-flex align-items-start mt-1">
                                                        <label htmlFor="fullName" style={{ fontWeight: 'bold', fontSize: '13px' }}>Name:</label>
                                                        <input
                                                            type="text"
                                                            name="name"
                                                            id="fullName"
                                                            value={`${studentData.firstname || ''} ${studentData.lastname || ''}`}
                                                            style={{ width: '70%', border: 'none', fontWeight: 'bold', margin: '0', fontSize: '13px' }}
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Citizenship:</label>
                                                        <input
                                                            type="text"
                                                            name="citizionShip"
                                                            value={studentData.nationality || ''}
                                                            style={{ width: '60%', border: 'none', fontSize: '13px' }}
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Passport No:</label>
                                                        <input
                                                            type="text"
                                                            name="passportNumber"
                                                            value={studentData.passportnumber || ''}
                                                            style={{ width: '60%', border: 'none', fontSize: '13px' }}
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Study Field:</label>
                                                        <input
                                                            type="text"
                                                            name="studyField"
                                                            value="Medicine (MBBS)"
                                                            style={{ width: '60%', border: 'none', fontSize: '13px' }}
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <label style={{ fontWeight: 'bold', fontSize: '13px' }}>College Fee Collected:</label>
                                                        <input
                                                            type="text"
                                                            name="collectedFee"
                                                            value={collectedFee || ''}
                                                            style={{ width: '30%', border: 'none', fontSize: '13px' }}
                                                        />
                                                    </div>
                                                    <div className="d-flex align-items-center mt-1">
                                                        <label style={{ fontWeight: 'bold', fontSize: '13px' }}>Pending Fee:</label>
                                                        <input
                                                            type="text"
                                                            name="pendingFee"
                                                            value={pendingFee || '0'}
                                                            style={{ width: '50%', border: 'none', fontSize: '13px' }}
                                                        />
                                                    </div>
                                                </td>

                                            </tr>
                                            <tr >
                                                <td style={{ textAlign: 'center', padding: '10px', borderRight: '1px solid #333333' }}>
                                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '5px' }}>
                                                        <label style={{ fontWeight: 'bold' }}>Date of Billing:</label>
                                                        <input
                                                            type="text"
                                                            value={dateoffeecollection}
                                                            style={{
                                                                width: '100%',
                                                                border: 'none',
                                                                textAlign: 'center', // Ensures input text is also centered
                                                                padding: '5px', // Adds some padding for better aesthetics
                                                                fontWeight: 'bold', // Optional, to make the text inside input stand out
                                                            }}
                                                        />
                                                    </div>
                                                </td>


                                                <td style={{ borderRight: '1px solid #333333' }}>
                                                    <p>Student Signature:</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Container><br /><br />
                                <p className="p-5">International relation office</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default BillingRecipt
