import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import dailyreport from '../Assets/dailyreport.png';
import collegefee from '../Assets/collegefee.png';
import hostelfee from '../Assets/hostelfee.png';
import messfee from '../Assets/messfee.png';
import total from '../Assets/total.png';
import overall from '../Assets/overall.png';
import classes from './Billing.module.css'
import msalInstance from "../msalConfig";


function Billings() {
  const [activeButton, setActiveButton] = useState('college');
  const [activeButtons, setActiveButtons] = useState('card');
  const { studentid } = useParams();
  const [studentData, setStudentData] = useState({});
  const [searchid, setSearchid] = useState('');
  const [hostelFee, setHostelFee] = useState(null);
  const [messFee, setMessFee] = useState(null);
  const [visaFee, setVisaFee] = useState(null);
  const [registrationFee, setRegistrationFee] = useState(null);
  const [college, setCollegeFee] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [pendingFeee, setPendingFeee] = useState(null);
  const [collectedFee, setCollectedFee] = useState(null);
  const [selectedPaymentType, setSelectedPaymentType] = useState("cash"); // State to track payment type


  const [BillingData, setBillingData] = useState({
    Billings: []
  });
  const [fee, setFee] = useState(null);


  const updatedBillData = async () => {
    // Ensure this function is marked as async to use 'await'
    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        throw new Error("No accounts found");
      }
      msalInstance.setActiveAccount(accounts[0]);
      const token = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"]
      });

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/collectedfee/addCollectedFee`,
        updatedBillData,
        {
          headers: {
            Authorization: `Bearer ${token.idToken}`
          }
        }
      );

      if (response.status === 200) {
        console.log("Data submitted successfully:", response.data);
      } else {
        console.error("Failed to submit data:", response.data);
      }
    } catch (error) {
      console.error("Error updating data:", error.response?.data || error.message);
    }
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        // Fetch the token
        const token = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"]
        });

        // Make the API request with the token
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/yearlyfee/${searchid}`,
          {
            headers: {
              Authorization: `Bearer ${token.idToken}`,
            }
          }
        );

        // Update state with the fetched data
        setCollectedFee(response.data.fee_collected);
        setFee(response.data.fee_to_be_collected);
        setHostelFee(response.data.hostel_fee);
        setMessFee(response.data.mess_fee);
        setVisaFee(response.data.visa_fee);
        setRegistrationFee(response.data.visa_extension_fee);
        setCollegeFee(response.data.fee_to_be_collected);


        console.log("hostel", response.data.hostel_fee);


        console.log(response.data);
        console.log(response.data.fee_to_be_collected);
      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    };

    fetchStudentData();
  }, [searchid]);  // Dependency array with searchid


  console.log("studentid", searchid);



  // Function to fetch data
  const fetchData = async () => {
    try {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        throw new Error("No accounts found");
      }
      msalInstance.setActiveAccount(accounts[0]);
      const token = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"]
      });
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/v1/collectedfee/calculatePendingFee?student_id=${searchid}`, {
        headers: {
          authorization: `Bearer ${token.idToken}`
        }
      });
      setPendingFeee(response.data.pending_fee);
      console.log("PendingFee:", response.data.pending);
      setCollectedFee(response.data.total_collected);
    } catch (error) {
      console.error("Error fetching student data:", error);
    }
  };

  useEffect(() => {
    fetchData();

  }, [searchid]);

  useEffect(() => {
    if (pendingFeee !== null) {
      console.log("PendingFee:", pendingFeee);
    }
  }, [pendingFeee]);


  const handleButtonClick = (button) => {
    setFees((prevFees) => ({
      ...prevFees,
      fee_type: button || "College",
    }));
    setActiveButton(button);
  };




  const handleButtonClicks = (type) => {
    setSelectedPaymentType(type); // Update selected payment type
    setBilldata({
      ...billdata,
      billingfor: [{ ...billdata.billingfor[0], ammounttype: type }],
    });
  };


  const buttonStyles = (button) => {
    return button === activeButton
      ? {
        backgroundColor: '#3A3F51',
        color: '#EEEEEE',
      }
      : {
        backgroundColor: 'rgba(37, 40, 54, 1)',
        color: '#EEEEEE',
        borderColor: 'transparent',
      };
  };

  const buttonStyle = (button) => {
    return button === selectedPaymentType
      ? {
        backgroundColor: '#3A3F51',
        color: '#EEEEEE',
      }
      : {
        backgroundColor: 'rgba(37, 40, 54, 1)',
        color: '#EEEEEE',
        borderColor: 'transparent',
      };
  };

  const handleSearchChange = (event) => {
    setSearchid(event.target.value);
  };


  const handleSearchSubmit = async () => {
    if (searchid) {
      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        throw new Error("No accounts found");
      }
      msalInstance.setActiveAccount(accounts[0]);
      var token = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"]
      });
      axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent/${searchid}`, {
          headers: {
            Authorization: `Bearer ${token.idToken}`
          }
        })
        .then((response) => {
          setStudentData(response.data);  // Set student data in state
          // Set the student ID in fees state
          setFees((prevFees) => ({
            ...prevFees,
            studentid: response.data.studentid,  // Set student ID in fees state
          }));
        })
        .catch((error) => {
          console.error("Error fetching student data:", error);
        });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit();
    }
  };

  useEffect(() => {
    const fetchStudentData = async () => {
      if (studentid) {
        const accounts = msalInstance.getAllAccounts();
        if (accounts.length === 0) {
          throw new Error("No accounts found");
        }
        msalInstance.setActiveAccount(accounts[0]);
        var token = await msalInstance.acquireTokenSilent({
          scopes: ["user.read"]
        });
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/v1/addstudent/addstudent/${studentid}`, {
            headers: {
              Authorization: `Bearer ${token.idToken}`
            }
          })
          .then((response) => {
            setStudentData(response.data);
          })
          .catch((error) => {
            console.error("Error fetching student data:", error);
          });
      }
    };

    fetchStudentData();
  }, [studentid]);

  const [billdata, setBilldata] = useState({
    transactions: "",
    billingfor: [
      {
        pay_for: "",
        ammounttype: "",
        accounttype: "",
        collegefee_collected: "",
        hostel_fee_collected: "",
        mess_fee_collected: "",
        total_collected: "",
        overall_pending: "",
        dateofbilling: "11-09-2024",
      },
    ],
  });

  const [fees, setFees] = useState({
    studentid: " ",  // Initially empty, will be filled with the actual student ID
    year: 0,  // Get the current year
    fee_collected: 0,  // Get the total fee collected
    fee_type: "",
    accound_type: "",
    transaction_id: "",
    date_of_fee_collection: Date.now(),
    yearly_fees: 0,
    discount: 0, // Get the discount
  });



  const handleFeesChange = (e) => {
    const { name, value } = e.target;
    console.log(`Name: ${name}, Value: ${value}`); // Debugging
    setFees((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChange = (e, field, index) => {
    console.log("handleChange triggered"); // Debugging
    const { name, value } = e.target;

    if (field === "fees") {
      console.log(`Account Type Updated: ${value}`);
      // Update account type based on selected radio button
      setFees((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else if (field === "billdata") {
      const updatedBillingfor = [...billdata.billingfor];
      updatedBillingfor[index][name] = value;
      setBilldata({
        ...billdata,
        billingfor: updatedBillingfor,
      });
    }
  };

  const handleBillingData = (e) => {
    const { name, value } = e.target;
    setBilldata((prevData) => ({
      ...prevData,
      billingfor: prevData.billingfor.map((bill) => ({
        ...bill,
        [name]: value,
      })),
    }));
  };

  const handleBilling = (e) => {
    const { name, value } = e.target;
    setBilldata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updatedata = async () => {
    // Basic validation to ensure all required fields are filled

    if (pendingFeee === 0) {
      alert("No pending fees. Payment cannot be added.");
      return;
    }

    if (!fees.studentid || !fees.year || !fees.fee_collected) {
      console.error("Validation Error: Student ID, Year, and Fee Collected are required.");
      alert(" Year, and Fee Collected are required.");
      return;
    }



    try {

      const accounts = msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        throw new Error("No accounts found");
      }
      msalInstance.setActiveAccount(accounts[0]);
      const token = await msalInstance.acquireTokenSilent({
        scopes: ["user.read"]
      });

      // Send the fee collection data to the backend
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/v1/collectedfee/addCollectedFee`,
        fees,
        {
          headers: {
            Authorization: `Bearer ${token.idToken}`
          }
        }
      );
      console.log("Response:", response.data);
      alert("Payment Added Successfully.");
    } catch (error) {
      console.error("Error updating data:", error.response?.data || error.message);
    }
  };

  // Fixed College Fee
  const collegeFee = 2600;

  // Extracting the college fee collected if available
  const totalPaidFee = studentData?.billingfor?.[0]?.collegefee_collected || "0";
  const totalPaidHostelFee = studentData?.billingfor?.[0]?.hostel_fee_collected || "0";
  const totalPaidMessFee = studentData?.billingfor?.[0]?.mess_fee_collected || "0";
  const totalPaidOverall = studentData?.billingfor?.[0]?.overall_pending || "0";
  const totalfee = studentData?.totalfee || "0";
  const pandingFee = totalfee - totalPaidFee;
  console.log("totalPaidFee", pandingFee);

  // Calculating the pending fee
  const pendingFee = totalPaidFee - collegeFee;

  // Dividing the remaining fee by 2 and adding it to hostel and mess fee
  const distributedFee = pendingFee / 2;
  const updatedHostelFee = parseInt(totalPaidHostelFee) + distributedFee;
  const updatedMessFee = parseInt(totalPaidMessFee) + distributedFee;

  return (
    <div className="container-fluid min-vh-100 d-flex justify-content-center" style={{ backgroundColor: '#1E1E2E' }}>
      <div className="row w-80">
        <nav
          style={{
            "--bs-breadcrumb-divider": `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E")`, // Custom breadcrumb divider
            fontSize: '1.5rem',
            fontWeight: 500,
            backgroundColor: '#1E1E2E'
          }}
          aria-label="breadcrumb"
          className="p-3 text-white"
        >
          <ol className="breadcrumb m-2">
            <li className="breadcrumb-item text-white">Accounts</li>
            <li className="breadcrumb-item active" aria-current="page"></li>
          </ol>
        </nav>
        <div className="container row mx-auto mb-5 gap-2 justify-content-center">
          <div className="col-lg-8">
            <div className={classes.container}>
              <div
                style={{
                  backgroundColor: 'rgba(37, 40, 54, 1)',
                  color: 'rgba(228, 228, 231, 1)',
                  borderRadius: '8px',
                  padding: '25px',
                }}
                className=" col-md-12 d-flex row justify-content-center"
              >
                <h5>Billings</h5>
                <div
                  className="container m-4 "
                  style={{ backgroundColor: '#2D2F3A', padding: '25px' }}
                >
                  <form>
                    <div className="row justify-content-center pt-3">
                      {/* Student ID */}
                      <div className="col-md-6 mb-3 d-flex justify-content-center">
                        <label htmlFor="studentId" className="form-label me-2" style={{ width: '70%' }}>
                          Student ID
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="studentId"
                          style={{ backgroundColor: '#3A3F51', color: 'white' }}
                          value={searchid} // Bind the search ID value
                          onChange={handleSearchChange} // Update the search ID state
                          onKeyDown={handleKeyDown}
                        />
                      </div>

                      {/* Full Name */}
                      <div className="col-md-6 mb-3 d-flex justify-content-center">
                        <label htmlFor="fullName" className="form-label me-2" style={{ width: '70%' }}>
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="fullName"
                          style={{ backgroundColor: '#3A3F51', color: 'white' }}
                          value={`${studentData.firstname || ''} ${studentData.lastname || ''}`}  // Display the full name if available
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      {/* Mobile Number */}
                      <div className="col-md-6 mb-3 d-flex justify-content-center">
                        <label htmlFor="mobileNumber" className="form-label me-2" style={{ width: '70%' }}>
                          Mobile Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="mobileNumber"
                          style={{ backgroundColor: '#3A3F51', color: 'white' }}
                          value={studentData.localphone || ''}
                          readOnly
                        />
                      </div>

                      {/* Email ID */}
                      <div className="col-md-6 mb-3 d-flex justify-content-center">
                        <label htmlFor="emailId" className="form-label me-2" style={{ width: '70%' }}>
                          Email ID
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="emailId"
                          style={{ backgroundColor: '#3A3F51', color: 'white' }}
                          value={studentData.email || ''}
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="row justify-content-center">
                      {/* Date of Birth */}
                      <div className="col-md-6 mb-3 d-flex justify-content-center">
                        <label htmlFor="dob" className="form-label me-2" style={{ width: '70%' }}>
                          Date of Birth
                        </label>
                        <input type="date" className="form-control" id="dob" style={{ backgroundColor: '#3A3F51', color: 'white' }}
                          value={studentData.dateofbirth || ''}
                          readOnly />
                      </div>

                      {/* College Year */}
                      <div className="col-md-6 mb-3 d-flex justify-content-center">
                        <label htmlFor="year" className="form-label me-2" style={{ width: '70%' }}>
                          Year
                        </label>
                        <input
                          type="text"
                          inputMode='numeric'
                          className="form-control"
                          id="year"
                          name="year"
                          style={{ backgroundColor: '#3A3F51', color: 'white' }}
                          value={fees.year || ""}
                          onChange={handleFeesChange}
                        />
                      </div>
                    </div>

                    <div className="row justify-content-start">
                      {selectedPaymentType === "Account" && (
                        <div className="col-12 mb-3 d-flex gap-2">
                          <label
                            htmlFor="transactionId"
                            className="form-label me-2"
                            style={{ width: "40%" }}
                          >
                            Transaction ID (Online)
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            style={{
                              backgroundColor: "#3A3F51",
                              color: "white",
                              borderColor: "#3E3E3E",
                            }}
                            name="transaction_id"
                            value={fees.transaction_id}
                            id='transaction_id'
                            onChange={handleFeesChange}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
                <div className='d-flex' style={{ backgroundColor: '#2D2F3A', padding: '25px' }}>
                  <div
                    className="container mt-4 "
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div className="btn-group col-6 mb-3" role="group" aria-label="Button group">
                      <button
                        type="button"
                        className="btn"
                        style={buttonStyles('College')}
                        checked={billdata.billingfor[0].pay_for === "College"}
                        onClick={() => handleButtonClick('College')}>
                        College
                      </button>
                      <button
                        type="button"
                        className="btn"
                        style={buttonStyles('Hostel')}
                        checked={billdata.billingfor[0].pay_for === "Hostel"}
                        onClick={() => handleButtonClick('Hostel')}
                      >
                        Hostel
                      </button>
                      <button
                        type="button"
                        className="btn"
                        style={buttonStyles('Mess')}
                        checked={billdata.billingfor[0].pay_for === "Mess"}
                        onClick={() => handleButtonClick('Mess')}
                      >
                        Mess
                      </button>
                      <button
                        type='button'
                        className='btn'
                        style={buttonStyles('Overall')}
                        checked={billdata.billingfor[0].pay_for === "Overall"}
                        onClick={() => handleButtonClick('Overall')}
                      >
                        Overall
                      </button>

                    </div>
                    <div className="btn-group col-4 mb-4" role="group" aria-label="Button group">
                      <button
                        type="button"
                        className="btn"
                        style={buttonStyle('Office')}
                        onClick={() => handleButtonClicks('Office')}
                      >
                        Office
                      </button>
                      <button
                        type="button"
                        className="btn"
                        style={buttonStyle('Account')}
                        onClick={() => handleButtonClicks('Account')}
                      >
                        Account
                      </button>
                    </div>
                    <div>
                      <div className='d-flex gap-3 mb-3'>
                        <div class="form-check d-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="accound_type"
                            id="flexRadioDelhi"
                            value="Delhi"
                            onChange={(e) => handleChange(e, "fees")}
                            checked={fees.accound_type === "Delhi"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDelhi"
                          >
                            Delhi Account
                          </label>
                        </div>

                        <div className="form-check d-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="accound_type"
                            id="flexRadioTamilNadu"
                            value="TamilNadu"
                            onChange={(e) => handleChange(e, "fees")}
                            checked={fees.accound_type === "TamilNadu"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioTamilNadu"
                          >
                            TamilNadu
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      height: '80%',
                      width: '2px',
                      backgroundColor: 'rgba(238, 238, 238, 0.6)',
                      margin: '15px 60px 0 0',
                    }}
                  ></div>

                  {/* Right Content: INR Text */}
                  <div
                    style={{
                      padding: '20px 30px 0 0',
                      color: 'rgba(238, 238, 238, 0.6)',
                      fontSize: '13px',
                      fontWeight: 700,
                      whiteSpace: 'nowrap',  // Ensures the text stays on a single line
                      // left:'40px'
                    }}
                  >

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', top: "20px" }}>
                      Total Fee
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', top: "20px" }}>
                      {pendingFeee} {/* Display fee or loading if not yet fetched */}
                    </div>
                    <input
                      type="text"  // Make it a number input
                      inputMode='numeric'
                      className="form-control custom-placeholder"
                      style={{ backgroundColor: 'rgba(58, 63, 81, 1)', color: 'white' }}
                      name="fee_collected"
                      value={fees.fee_collected || ""}
                      onChange={handleFeesChange}  // Handle the change in the input value
                      placeholder="Total amount received"
                    />


                    <style>
                      {`
                      .custom-placeholder::placeholder {
                        color: rgba(255, 255, 255, 0.7); /* Light gray placeholder */
                      }
                    `}
                    </style>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-end gap-3" // Align buttons to the right with gap in between
                  style={{ padding: '20px 0' }} // Optional: Add some padding if needed
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={async () => {
                      await updatedata();
                      await fetchData();
                    }}
                    style={{

                      color: '#EEEEEE', // Submit button font color
                      borderColor: 'rgba(41, 112, 255, 1)', // Submit button border color
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* {isModalVisible && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                backdropFilter: "blur(8px)", // Apply blur effect
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
              }}
            >
              <div
                style={{
                  backgroundColor: "#1E1E2E",
                  padding: "20px 30px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
                  textAlign: "center",
                  width: "90%",
                  maxWidth: "400px",
                  zIndex: 1001,
                }}
              >
                <p
                  style={{
                    marginBottom: "15px",
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "#EEE",
                  }}
                >
                  {modalMessage}
                </p>
                <button
                  onClick={closeModal}
                  style={{
                    backgroundColor: "#007bff",
                    color: "white",
                    border: "none",
                    padding: "8px 16px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          )} */}
          <div className="col-lg-3">
            <div className="row gap-3">
              <div className={classes.content}>
                <div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{ width: '300px', height: '80px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
                >
                  <div>
                    <span className={classes.cardsnumber}>Daily Report</span>
                  </div>
                  <img src={dailyreport} width={30} height={30} alt="Total Events" />
                </div>
              </div>
              <div className={classes.content}>
                <div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{ width: '300px', height: '100px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
                >
                  <div>
                    <span className={classes.cardstitles}>College fee Collected</span><br />
                    <span className={classes.cardsnumber}>{college}</span>
                  </div>
                  <img src={collegefee} width={40} height={40} alt="Total Tickets Sold" />
                </div>
              </div>
              <div className={classes.content}>
                <div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{ width: '300px', height: '100px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
                >
                  <div>
                    <span className={classes.cardstitles}>Hostel Fee collected</span><br />
                    <span className={classes.cardsnumber}>{hostelFee}</span>
                  </div>
                  <img src={messfee} width={40} height={40} alt="Total Tickets Sold" />
                </div>
              </div>

              <div className={classes.content}>
                <div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{ width: '300px', height: '100px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
                >
                  <div>
                    <span className={classes.cardstitles}>Visa Registration</span><br />
                    <span className={classes.cardsnumber}>{visaFee}</span>
                  </div>
                  <img src={total} width={40} height={40} alt="Total Tickets Sold" />
                </div>
              </div>
              <div className={classes.content}>
                <div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{ width: '300px', height: '100px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
                >
                  <div>
                    <span className={classes.cardstitles}>Mess Fee Collected</span><br />
                    <span className={classes.cardsnumber}>{messFee}</span>
                  </div>
                  <img src={messfee} width={40} height={40} alt="Total Tickets Sold" />
                </div>
              </div>
              <div className={classes.content}>
                <div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{ width: '300px', height: '100px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
                >
                  <div>
                    <span className={classes.cardstitles}>Visa Extension</span><br />
                    <span className={classes.cardsnumber}>{registrationFee}</span>
                  </div>
                  <img src={hostelfee} width={40} height={40} alt="Total Tickets Sold" />
                </div>
              </div>
              <div className={classes.content}>
                <div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{ width: '300px', height: '100px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
                >
                  <div>
                    <span className={classes.cardstitles}>Total Collected</span><br />
                    <span className={classes.cardsnumber}>{collectedFee}</span>
                  </div>
                  <img src={total} width={40} height={40} alt="Total Tickets Sold" />
                </div>
              </div>


              {pendingFeee ? (
                <div className={classes.content}>

                  <div
                    className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                    style={{ width: '300px', height: '100px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
                  >

                    <div>
                      <span className={classes.cardstitles}>Overall Pending</span><br />
                      <span className={classes.cardsnumber}>{pendingFeee}</span>
                    </div>
                    <img src={overall} width={40} height={40} alt="Total Revenue" />
                  </div>
                </div>
              ) : (
                <div className={classes.content}><div
                  className="col-md-12 p-3 d-flex justify-content-between align-items-center rounded-2"
                  style={{ width: '300px', height: '100px', backgroundColor: 'rgba(45, 47, 58, 1)' }}
                >

                  <div>
                    <span className={classes.cardstitles}>Overall Pending</span><br />
                    <span className={classes.cardsnumber}>0</span>
                  </div>
                  <img src={overall} width={40} height={40} alt="Total Revenue" />
                </div>
                </div>)}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Billings;
